import React, {useState} from 'react';
import Proration from './Proration';
import Button from 'react-bootstrap/Button';
import auth from '../Account/Auth';
import be from '../BE';

function DowngradeMembers({getTeamStatus,canUpgrade,viewerRole,selectedMembers,teamId}){
  const [showProrationModal, setShowProrationModal] = useState(false);
  const [prorationDetails, setProrationDetails] = useState(null); // New state for proration details
  const [loadingPreview,setLoadingPreview] = useState(false);

  const getMemberKeys = (members) => {
    return members.map(member => member.key);
  };

  const areAllFieldsPresent = (obj) => {
    return Object.values(obj).every(value => value !== null && value !== undefined);
  }

  const handleProrationModalClose = async () => {
    setShowProrationModal(false);
    setProrationDetails(null);
    await getTeamStatus();
  };

  async function upgradeMembers(_members){
    setLoadingPreview(true);
    let body = {
      teamId: teamId,
      members: getMemberKeys(_members),
      operation: "downgrade"
    }
    await auth.isAuthenticated()
      .then(async () => {
        await be.post("payment-jubilant","/team/members/preview",body,true,true)
          .then((response) => {
            const updateObj = {
              action: response?.action,
              amount: response?.amount,
              currency: response?.currency_code,
              quantity: _members.length
            }
            if(areAllFieldsPresent(updateObj)){
              setProrationDetails(updateObj);
              setShowProrationModal(true);
            } 
          })
          .catch((error) => {
            console.log(error);
          })
        setLoadingPreview(false);
      })
      .catch(()=>{})
  }

  const shouldDisable = () => {
    const areAllPlansSame = (members) => {
      if (members.length === 0) return true;
      const firstPlan = members[0].plan;
      if (firstPlan === "free") return true;
      return members.every(member => (member?.plan !== "free" && (member.plan === firstPlan)));
    };

    if(areAllPlansSame(selectedMembers)){
      if(canUpgrade){
        // enable upgrade if the flag canUpgrade is true
        return false;
      } else if (viewerRole !== "owner"){
        // disable if request is loading or who wants to upgrade is not owner
        return true;
      } else {
        // enable in other cases
        return false;
      }
    } else {
      // disable upgrade if selected members have different plans
      return true;
    }
  }


  const disabledBtn = shouldDisable();

  return(
    <>
      <Button
        disabled={disabledBtn || loadingPreview}
        size="sm"
        variant="danger"
        onClick={() => upgradeMembers(selectedMembers)}
      >
        Downgrade Selected {loadingPreview && "..."}
      </Button>

      <Proration
        show={showProrationModal}
        onHide={handleProrationModalClose}
        prorationDetails={prorationDetails}
        teamId={teamId}
        members={getMemberKeys(selectedMembers)}
        operation="downgrade"
      />
    </>
  );
}

export default DowngradeMembers;
