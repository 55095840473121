import React from 'react';
import {Container, Row, Col, Alert, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import _Details from '../Booking/Details/_Details';
import spacetime from 'spacetime'
import be from '../BE';
import auth from '../Account/Auth';

class _Delete extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      event : null,
    };
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  // TODO: controllare errori, re-rendering
  // actually we should display in this page and confrimation page
  // the datetime depending on who is cancelling this event.
  // if it's the owner, then we have the timezone in ownerInfo.timezone
  // if it's the guest, then we need the that timezone from backend
  // i can distinguish who is making the request by comparing profileObj
  // session email with ownerInfo.email.
  // if the email corresponds, then the owner is deleting the event
  // if the email is not the same, then the guest is deleting this event
  // (maybe later we can send to BE the email that deleted the event as
  // soft delete extra info)
  // ---------
  // at the moment : only display for owner timezone
  deleteEvent (bookingId){
    const body = { bookingid : bookingId }
    be.del('booking','/booked',body,true)
    .then((response) => {

      let isoDatetime = spacetime(this.state?.event?.date,this.state?.event?.details?.ownerInfo?.timezone)
      isoDatetime = isoDatetime.time(this.state?.event?.time)
      console.log(isoDatetime)
      isoDatetime = isoDatetime.format('iso')

      this.props.history.push({
        pathname:`/operations/confirm/${bookingId}`,
        state:{ 
          deleted:true,
          owner:this.state?.event?.details?.ownerInfo?.fullName,
          event:this.state?.event?.details?.eventInfo?.name,
          description:this.state?.event?.details?.eventInfo?.description,
          // we do not have RFCDateTimeGuest
          datetime:isoDatetime,
          duration:this.state?.event?.details?.eventInfo?.duration,
          // we do not have guest timezone
          timezone:this.state?.event?.details?.ownerInfo?.timezone,
          redirectionUrl:this.state?.event?.details?.eventInfo?.redirectionUrl,
          location:this.state?.event?.details?.eventInfo?.location
        }
        
      })
    })
    .catch(error => console.log(error));
  }

  async componentDidMount(){
    // get Booked Event
    const queryParams = {bookingid : this.props.match.params.bookingid};
    await auth.isAuthenticated()
    .then(() => {
      be.post('booking','/booked',queryParams,true)
      .then(response => {this.setState({event : response})})
      .catch(error => console.log(error));
    })
    // redirect istantaneo alla pagina login se non sono autenticato
    .catch(() => this.props.history.push('/login'));
  }

  render(){

    return (
      <>
        <Container className="mt-5 mb-5">
        {this.state.event
          ?
          <>
            <Alert className="h3" variant="primary">Delete Event: {this.state.event.date} - {this.state.event.time}</Alert>
            <Row>
              <Col> <_Details state={this.state.event.details}/> </Col>
            </Row>
            <Row>
              <Col> <Button onClick={() =>(this.deleteEvent(this.props.match.params.bookingid))} className="my-2" block>Delete Event</Button> </Col>
            </Row>
          </>
          :
          <Row className="mx-auto">
            Loading...
          </Row>
        }
        </Container>

      </>
    );
  }
}

export default _Delete;
