import Order from './Order';
import Content from './Content';
import Required from './Required';
import TextAnswer from './TextAnswer';
import Fields from './Fields/_Fields';

function Question({questionState,questionId}){
  return(
    <>
      <div className="mt-3">
        <Order order={questionState.order}/>
        <Content content={questionState.content}/>    
        <Required required={questionState.required}/>
      </div>
      {questionState.questionType === 3
        ?
          <TextAnswer questionId={questionId} required={questionState.required}/>
        :
          <Fields type={questionState.questionType} fields={questionState.qstFields} questionId={questionId} required={questionState.required}/>
      }
    </>
  );
}

export default Question;
