import {useLocation,useHistory} from 'react-router-dom';
import {useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ConnectedCalendars from './ConnectedCalendars/_ConnectedCalendars';
import AddCalendar from './AddCalendar';
import Calendar from './Calendar';
import CheckConflicts from './CheckConflicts/_CheckConflicts';
import AddEvents from './AddEvents/_AddEvents';
import {useCalendarConnectionsStore} from '../store';
import auth from '../Account/Auth';
import be from '../BE';
import ReactGA from "react-ga4";

function CalendarConnections(){
  ReactGA.send({ hitType: "pageview", page: "/calendarconnections", title: "Calendar Connections" });
  try{window.tidioChatApi.show()}catch{}
  const { t } = useTranslation(['calendarConnections']);
  const calendarsList = useCalendarConnectionsStore((state) => state.calendarsList);
  const connectedCalendars = useCalendarConnectionsStore((state) => state.connectedCalendars);
  const maxCalendars = useCalendarConnectionsStore((state) => state.maxCalendars);
  const setCalendarsList = useCalendarConnectionsStore((state) => state.setCalendarsList);
  const setConnectedCalendars = useCalendarConnectionsStore((state) => state.setConnectedCalendars);
  const setMaxCalendars = useCalendarConnectionsStore((state) => state.setMaxCalendars);
  const [loading,setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const queryParameters= new URLSearchParams(location.search)
  const referrer = queryParameters.get("referrer")

  if (referrer){
    console.log(referrer)
  }

  useEffect(() => {
    async function getCalendarConnections(){
      setLoading(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('calendarhandler',"/getCalendars",false,true)
            .then((response) => {
              setCalendarsList(response?.calendarsList);
              setConnectedCalendars(response?.connectedCalendars);
              setMaxCalendars(response?.maxCalendars);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        })
        .catch(() => history.push({pathname:'/login'}))
    }
    getCalendarConnections();
  },[]);

  return(
    <>
      <Row style={{maxWidth:1024}} className="mt-0 mt-md-5 mx-0 mx-md-auto d-flex">
          
        <div style={{marginTop:20,marginBottom:20}}>
          <div style={{display:'flex'}}>
            <div style={{marginRight:10,fontSize:24,fontWeight:600}}>{t('connectedCalendars')}</div>
            <div style={{fontSize:18,color:'var(--color-secondary)',display:'flex',margin:'auto 0'}}>{connectedCalendars}/{maxCalendars}</div>
            <div style={{marginLeft:'auto'}}><AddCalendar connectedCalendars={connectedCalendars}/></div>
          </div>
          <small style={{color:'var(--color-secondary)'}}>{t('subtitle')}</small>
        </div>

        {loading && <center><Spinner/></center>}
        
        {calendarsList && calendarsList.map((calendar,index) => {
          return(
            <Calendar
              key={`calendar-connection-${calendar?.email}`}
              index={index}
              fullName={calendar?.fullName}
              email={calendar?.email}
              pic={calendar?.calendarPic}
              access={calendar?.access}
              calendars={calendarsList}
              setCalendars={setCalendarsList}
              sync={calendar?.sync}
            />
          )
        })}

      </Row>
    </>
  );
}

export default CalendarConnections;
