import {useTranslation} from 'react-i18next';
import React, { useEffect } from 'react';
import {useCreateBookingStore} from '../../../store';
import be from '../../../BE';
import Question from './Question/_Question';

function Surveys({etype,owner}){
  const {t} = useTranslation(['booking']);
  const storeSurveys = useCreateBookingStore(state => state.surveys);
  const setSurveys = useCreateBookingStore(state => state.setSurveys);

  useEffect (() => {
    // fetching surveys from BE
    const loadSurveys = (_etype,_owner) => {
      const surveyBody = {owner: _owner, etype : _etype}
      be.get('survey','/bookingSurveys',surveyBody)
        .then(response => {
          // ordering questions by 'order' value
          const orderedSurveys = Object.fromEntries(Object.entries(response).sort(([,a],[,b]) => a.order-b.order));
          // set ordered response to state
          setSurveys(orderedSurveys)
        })
        .catch(error => console.log(error))
    }
    loadSurveys(etype,owner); 
  },[etype,owner,setSurveys])

  const hasQuestions = Object.keys(storeSurveys).length !== 0

  return(
    <>
      {hasQuestions &&
        <>
          <div className="h4">{t('final.questions')}</div>
          {
            Object.keys(storeSurveys).map((key) => (
              <Question key={`question-${key}`} questionState={storeSurveys[key]} questionId={key}/>
            ))
          }
        </>
      }
    </>
  );
}

export default Surveys;
