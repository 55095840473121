import be from '../BE';
import {Amplify,Auth as AmplifyAuth} from 'aws-amplify';
import * as Sentry from "@sentry/react";
import toast from 'react-hot-toast';

// printing console.info logs only in localhost
const DEBUG = window.location.hostname === 'localhost';
var CLIENT = ""
if(DEBUG){CLIENT = "8oqrf7a5het2vkb0u06km15us"}
else{CLIENT = "4de86ece06uce62qeoqvsfkuef"}

Amplify.configure({
  Auth:{
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_j3ieIXUaN',
    userPoolWebClientId: CLIENT,
    mandatorySignIn: true
  }
})

class Auth {

  async login(params,path = '/account/login'){
   toast.loading(`Logging in...`)
    const createAccessObj = (_challengeResult) => {
      const access = _challengeResult?.signInUserSession?.accessToken?.jwtToken;
      const refresh = _challengeResult?.signInUserSession?.refreshToken?.token;
      const accessObj = {access_token:access,refresh_token:refresh};
      return accessObj;
    }
    const getUserInfo = async (_accessObj) => {
      localStorage.setItem('userSession', JSON.stringify({access:_accessObj}));
      await be.get("account-jubilant","/account/me",false,true,true)
        .then(response => {
          localStorage.setItem('userSession', JSON.stringify({access:_accessObj,profileObj:response}));
          DEBUG && console.info("%c[auth][login] built localStorage userSession",'color:lightgreen');
        })
        .catch(e=> {
          DEBUG && console.info(`%c[auth][login][error] unable to set localStorage userSession: ${e?.message}`,'color:salmon');
          localStorage.removeItem('userSession');
          DEBUG && console.info("[auth][login] cleaned userSession localStorage")
        })
    }
    // Guess timezone on OS settings
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // Get language from i18next localStorage
    let lng = localStorage.getItem('i18nextLng')
    // Append timezone to params
    params.timezone = timezone
    // Append language to params
    params.language = lng
    await be.post('account-jubilant',path,params,false,true)
     .then(async response => {
       const {id,key} = response;
       DEBUG && console.info(`[auth][login] calling cognito sign in with\nid: ${id}\nkey: ${key}...`)
       const cognitoUser = await AmplifyAuth.signIn(id,key);
       DEBUG && console.info(`[auth][login] creating profileObj...`)
       await getUserInfo(createAccessObj(cognitoUser))
       DEBUG && console.info(`[auth][login] created profileObj: ${JSON.parse(localStorage.getItem('userSession'))}`)
       return Promise.resolve(true);
    })
    .catch(error =>{
        return Promise.reject(false);
    });
    toast.dismiss();
  }

  async refresh(){
    
    const createAccessObj = (_refreshResponse) => {
      const access = _refreshResponse?.accessToken?.jwtToken;
      const refresh = _refreshResponse?.refreshToken?.token;
      const accessObj = {access_token:access,refresh_token:refresh};
      return accessObj;
    }
    const getUserInfo = async (_accessObj) => {
      localStorage.setItem('userSession', JSON.stringify({access:_accessObj}));
      await be.get("account-jubilant","/account/me",false,true,true)
        .then(response => {
          localStorage.setItem('userSession', JSON.stringify({access:_accessObj,profileObj:response}));
          DEBUG && console.info("%c[auth][refresh] built localStorage userSession with profileObj",'color:lightgreen');
            })
            .catch(e=> {
                DEBUG && console.info(`%c[auth][refresh][error] unable to set localStorage userSession: ${e?.message}`,'color:salmon');
                localStorage.removeItem('userSession');
                DEBUG && console.info("[auth][refresh] cleaned localStorage userSession")
            })
    }

    try{

      DEBUG &&console.info("refresh: get cognito user")
      const cognitoUser = await AmplifyAuth.currentAuthenticatedUser()
      DEBUG &&console.info("cognito user: ", cognitoUser)

      console.info("refresh: get current session")
      const currentSession = await AmplifyAuth.currentSession();
      DEBUG &&console.info("current session: ",currentSession)

      DEBUG &&console.info("refresh: update access using refresh token")
      const {refreshToken} = cognitoUser?.signInUserSession;

      if(refreshToken){
        await new Promise((resolve,reject) => {
          cognitoUser.refreshSession(refreshToken, async (error,session) => {
            if(error){
              DEBUG && console.info(`%c[auth][refresh][error] if error: unable to refresh session: ${error?.message}`,'color:salmon');
              reject(false);
            }else{
              DEBUG && console.info(`%c[auth][refresh] refreshed session`,'color:lightgreen');
              DEBUG && console.info(`[auth][refresh] getting user info from /me endpoint...`)
              const accessObj = createAccessObj(session);
              await getUserInfo(accessObj)
              resolve();
            }
          })
        })
      }
    }catch(error){
      DEBUG && console.info(`%c[auth][refresh][error] catch: unable to refresh session: ${error?.message}`,'color:salmon');
      DEBUG && console.error(error)
      return Promise.reject(false);
    }

    /*
    await be.refresh('account','/refresh')
    .then(response => {
      //salvo la sessione
      localStorage.setItem('userSession', JSON.stringify(response));
      //restituisco esito positivo
      return Promise.resolve(true);
    })
    .catch(error => {
      //restituisco esito negativo
      return Promise.reject(false);
    });
    */
  }

  async logout(){
    DEBUG && console.info("[logout] deleting calendbook session");
    localStorage.removeItem('userSession');
    localStorage.removeItem('agentSession');
    DEBUG && console.info("[logout] getting cognito user session");
    try{
      await AmplifyAuth.currentAuthenticatedUser()
        .then(async (response) => {
          DEBUG && console.info("[logout] saving cognito user session keys");
          const cognitoStorage = response?.storage;
          DEBUG && console.info("[logout] deleting cognito session keys: ");
          Object.keys(cognitoStorage).map(key => {
            if(key.startsWith("CognitoIdentityServiceProvider")){
              localStorage.removeItem(key);
              DEBUG && console.info(key);
            }
          })
        })
        .catch((error) => {
          DEBUG && console.info("[logout] unable to get cognito user session: ",error)
        })
    }catch(error){
      DEBUG && console.info("[logout] unable to get cognito user session: ",error)
    }
    window.location.href = '/login';
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  getExp(token){
    let exp = this.parseJwt(token).exp;
    exp *= 1000;
    DEBUG && console.info(`expiration: ${new Date(parseInt(exp))}`);
    return exp;
  }

  sessionExist(){
    if (localStorage.getItem("userSession") === null){
      return false;
    }
    return true;
  }

  getSession(){
    if(this.sessionExist()){
      return JSON.parse(localStorage.getItem('userSession'));
    }else{
      window.location.href = '/login';
    }
  }

  async isAuthenticated(){
    DEBUG && console.info("--------------")
    if(this.sessionExist()){
      DEBUG && console.info("session exists")
      let now = new Date();
      let sessionExpiration = new Date(parseInt(this.getExp(this.getAccessToken())));
      //se la sessione è scaduta
      if(now >= sessionExpiration){
        DEBUG && console.info("session expired, refreshing")
        // chiamo refresh e restituisco l'esito
        await this.refresh()
          .then((response) => {
            DEBUG && console.info("refreshed",response)
            return response;
          })
      }else{
        // altrimenti la sessione è ancora valida, restituisco true
        DEBUG && console.info("session still valid")
        return Promise.resolve(true);
      }
    }else{
      DEBUG && console.info("session not exists")
      return Promise.reject(false);
    }
    DEBUG && console.info("--------------")
  }

  getUsername(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.username;
  }

  /* 
   * return slugName if exists and is not empty string
   * else, return username
  */
  getName(){
    let session = this.getSession();
    if(session){
      try{
        if (session?.profileObj?.slugName && session?.profileObj?.slugName !== 'None'){
          return session?.profileObj?.slugName;
        }else{
          return session?.profileObj?.username;
        }
      }catch{
        return session?.profileObj?.username;
      }
    }
  }

  getFullName(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.name;
  }

  getEmail(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.email;
  }


  getPic(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.pic;
  }

  getBanner(){
    let session = this.getSession();
    if(session){
      if(session?.profileObj?.companyLogo){
        return session?.profileObj?.companyLogo;
      }else{
        return '/app-assets/img/standard-banner-pic.png';
      }
    }
  }

  getRole(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.planStatus?.status;
  }
  getAgencyId(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.agencyId;
  }
  getAgent(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.agent;
  }
  getTimezone(){
    let session = this.getSession();
    if(session)
    return session?.profileObj?.timezone;
  }
  getLanguage(){
    let exist = this.sessionExist();
    if(exist){
      let session = this.getSession();
      if(session)
        return session?.profileObj?.language.substring(0,2)
    }
  }

  getVendor(){
    let exist = this.sessionExist();
    if(exist){
      let session = this.getSession();
      if(session)
        return session?.profileObj?.vendor
    }
  }

  getExpiration(){
    let session = this.getSession();
    if(session)
      return session?.profileObj?.planStatus?.expiration;
  }

  getAccessToken(){
    return (this.getSession()?.access?.access_token);
  }

  getRefreshToken(){
    return (this.getSession()?.access?.refresh_token);
  }

  isFree(){
    return (this.getRole() === 'free' ? true : false);
  }

  isTrial(){
    return (this.getRole() === 'trial' ? true : false);
  }

  isPremium(){
    return (this.getRole() === 'premium' ? true : false);
  }

  isLifetime(){
    return (this.getRole() === 'LTD' ? true : false);
  }

  /**
   * Logs a message or error to Sentry, including optional breadcrumb data for better error tracking and diagnosis.
   * This function is intended to abstract Sentry logging and error reporting into a single reusable utility.
   *
   * @param {Object} params - The parameters for logging and error reporting.
   * @param {string} params.category - The category of the breadcrumb for grouping in Sentry.
   * @param {string} params.message - The message to be logged as a breadcrumb or error description.
   * @param {Sentry.Severity} [params.level='error'] - The severity level of the breadcrumb or error. Defaults to 'error'.
   * @param {Object} [params.data={}] - Additional data to be attached with the breadcrumb or error for more context.
   * @param {Error} [params.error=null] - The error object to be reported to Sentry. If provided, captures an exception instead of logging a breadcrumb.
   * @example
   * // To log a simple info message with data
   * logWithSentry({
   *   category: 'authentication',
   *   message: 'User login attempt',
   *   data: { username: 'johndoe' },
   * });
   *
   * @example
   * // To report an error to Sentry with additional context
   * logWithSentry({
   *   category: 'database',
   *   message: 'Failed to query user data',
   *   level: 'error',
   *   data: { query: 'SELECT * FROM users;' },
   *   error: new Error('Database unreachable'),
   * });
   */
  logWithSentry({ category, message, level, data, error }) {
    Sentry.addBreadcrumb({
      category,
      message,
      level: level || 'error',
      data,
    });

    if (error) {
      Sentry.captureException(error);
    }
  }


  /**
   * Retrieves a value from local storage based on a specified path. Optionally applies a callback to the retrieved value.
   *
   * @param {Object} params - The parameters object.
   * @param {string} params.storageKey - The key in local storage from which to retrieve data.
   * @param {Array<string>} params.path - The path to the property within the stored data.
   * @param {*} [params.fallback=null] - The fallback value to return if the path is not found or an error occurs.
   * @param {Function} [params.callback=null] - An optional callback to apply to the retrieved value.
   * @returns {*} The value from local storage at the specified path, the fallback value if not found, or the result of the callback if provided.
   * @throws {TypeError} If the path is not an array.
   * @example
   * // Assuming local storage contains: { userSession: { profile: { name: "John Doe" } } }
   * const userName = getProperty({
   *   storageKey: 'userSession',
   *   path: ['profile', 'name'],
   *   fallback: 'Anonymous',
   * });
   * console.log(userName); // Outputs: John Doe
   */
  getProperty({ storageKey, path, fallback = null, callback = null }) {
    if (!Array.isArray(path)) {
      throw new TypeError("Path must be an array.");
    }

    try {
      const rawSession = localStorage.getItem(storageKey);

      const session = rawSession ? JSON.parse(rawSession) : null;
      if (!session) return fallback ?? null;

      const value = path.reduce((acc, key) => acc?.[key], session);
      if (!value) return fallback ?? null;

      return callback ? callback(value) : value;
    } catch (error) {
      this.logWithSentry({
        category: 'getProperty',
        message: `Failed to get property from ${storageKey}`,
        data: { storageKey, path, fallback },
        error,
      });
      return fallback ?? null;
    }
  }


  /**
   * Sets a value in local storage for a specified path. Creates any necessary intermediate objects along the path.
   *
   * @param {Object} params - The parameters object.
   * @param {string} params.storageKey - The key in local storage under which to store data.
   * @param {Array<string>} params.path - The path to the property within the stored data where the value should be set.
   * @param {*} params.valueToSet - The value to set at the specified path.
   * @throws {TypeError} If the path is not an array or attempts to set a property on a non-object.
   * @example
   * // To set the user's name in local storage under the userSession key
   * setProperty({
   *   storageKey: 'userSession',
   *   path: ['profile', 'name'],
   *   valueToSet: 'Jane Doe',
   * });
   * // This will update local storage to include: { userSession: { profile: { name: "Jane Doe" } } }
   */
  setProperty({ storageKey, path, valueToSet }) {
    if (!Array.isArray(path)) {
      throw new TypeError("Path must be an array.");
    }

    try {
      const rawSession = localStorage.getItem(storageKey);
      let session = rawSession ? JSON.parse(rawSession) : {};

      let current = session;
      path.slice(0, -1).forEach((key, index) => {
        if (current[key] === undefined || current[key] === null) current[key] = {};
        else if (typeof current[key] !== 'object' || Array.isArray(current[key])) {
          throw new TypeError(`Attempted to set a property on a non-object at ${path.slice(0, index + 1).join('.')}`);
        }
        current = current[key];
      });

      current[path[path.length - 1]] = valueToSet;

      localStorage.setItem(storageKey, JSON.stringify(session));
    } catch (error) {
      this.logWithSentry({
        category: 'setProperty',
        message: `Failed to set property in ${storageKey}`,
        data: { storageKey, path, valueToSet },
        error,
      });
    }
  }
}

export default new Auth();
