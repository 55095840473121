import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';
import _Overview from './Overview/_Overview';
import Back from './Back';
import FinalForm from './FinalForm';


function _Final(props){
  return(
    <>
      <style dangerouslySetInnerHTML={{__html: `.final { min-height: 400px; min-width: 350px}`}} />
      <Card style={{background : "var(--color-background-1)"}} className="final">

        <Card.Header className="" style={{width:"inherit",margin:0}}>
          <div className="me-3 d-inline-block">
            <Back className="float-start" reselect={props.reselect}/>
          </div>
          <div className="d-inline-block">
            <_Overview selectedDay={props.selectedDay} selectedTime={props.selectedTime} ampm={props.ampm}/>
          </div>
        </Card.Header>

        <Card.Body>
          <FinalForm submit={props.submit} logged={props.logged} reschedule={props.reschedule} submitting={props.submitting} etype={props.etype} owner={props.owner} isPaidEvent={props.isPaidEvent}/>
        </Card.Body>
      </Card>
    </>
  );
}

export default _Final;
