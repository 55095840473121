import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function EventDuration(props){
  return(
    <>
      <i class="fas fa-hourglass"></i> {props.state} min
    </>
  );
}

export default EventDuration;
