import { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Header from './Header/Header';
import Subscription from './Subscription/Subscription';
import PricingCard from '../Landing/PricingCard/_PricingCard';
import auth from '../Account/Auth';
import be from '../BE';
import ReactGA from "react-ga4";
import LandingPage from './Agency/LandingPage/LandingPage';

const DEBUG = (window.location.hostname === 'localhost' || window.location.hostname === 'dev.calendbook.com');
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

const TEST_YEARLY_PREMIUM = "pri_01hnd8w440pst7jaenb4pxjeap";
const TEST_MONTHLY_PREMIUM = "pri_01hnd8x6tzhbd13qw6d7tdkyn5";
const LIVE_YEARLY_PREMIUM = "pri_01hg8swx54tmf43w05cpgk97cc";
const LIVE_MONTHLY_PREMIUM = "pri_01hg8sw20z338km4mns1n08bxg"

const TEST_YEARLY_PROFESSIONAL = "pri_01hrc18ywbwns9mev9kqk54e03";
const TEST_MONTHLY_PROFESSIONAL = "pri_01hrc188kzp7m8x2paw3x6chwq";
const LIVE_YEARLY_PROFESSIONAL = "pri_01hrc1cdmrhmnz9t7xpz1jp15y";
const LIVE_MONTHLY_PROFESSIONAL = "pri_01hrc1bfh4v2whzkvy9d4db067"

const TEST_YEARLY_AGENCY_TIER_1 = "pri_01hpppgpswcs3m0bgsv6y4s5w7";
const TEST_YEARLY_AGENCY_TIER_2 = "pri_01hpppj8gv63sqrpfy0m903y54";
const TEST_YEARLY_AGENCY_TIER_3 = "pri_01hpppmdaz4wfanashe34nrqn0";
const TEST_MONTHLY_AGENCY_TIER_1 = "pri_01hpppfymkva8vdrkdc9txpzya";
const TEST_MONTHLY_AGENCY_TIER_2 = "pri_01hppphhxptgqa11m9rqyfbte9";
const TEST_MONTHLY_AGENCY_TIER_3 = "pri_01hpppkttbrjt8trn5jmy73ks1";
const LIVE_YEARLY_AGENCY_TIER_1 = "pri_01hprnktqvvgf6akr4h7sb5nmg";
const LIVE_YEARLY_AGENCY_TIER_2 = "pri_01hprnpk590pqkqc02jzhga165";
const LIVE_YEARLY_AGENCY_TIER_3 = "pri_01hprns7fxfacgn6mkjv4qwz2h";
const LIVE_MONTHLY_AGENCY_TIER_1 = "pri_01hprnjbazy7hftyh1cxvx8qtp";
const LIVE_MONTHLY_AGENCY_TIER_2 = "pri_01hprnnkjrcgt8mv7vy8s4fp5k";
const LIVE_MONTHLY_AGENCY_TIER_3 = "pri_01hprnr0jz2q72fqgn1z2wg0ww";

function Upgrade({featureName}){
  ReactGA.send({ hitType: "pageview", page: "/upgrade", title: "Upgrade" });
  try{window.tidioChatApi.show()}catch{}
  const {i18n,t} = useTranslation(['landing','common',"upgrade"]);
  const [loading, setLoading] = useState(false);
  const [paddle, setPaddle] = useState();
  const [view, setView] = useState(window.location.hash === '#agency' ? 'agency' : 'upgrade');
  const [subscription,setSubscription] = useState({});
  const [pricingCadency,setPricingCadency] = useState("yearly");
  const [customerId,setCustomerId] = useState("");

  const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}

  var createNestedObject = function( base, names ) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for( var i = 0; i < names.length; i++ ) {
      base = base[ names[i] ] = base[ names[i] ] || {};
    }
  };

  const sleep = (ms) => {
    /* usage: await sleep(3000); */
    return new Promise((resolve) => setTimeout(resolve,ms));
  }

  const getSubscriptions = async () => {
    setLoading(true)
    // check if subscription has extra fields
    // if yes, the customer already has a subscription
    function checkForAdditionalFields(response, reference = {customerId:null}) {
      for (let key in response) {
        if (!(key in reference)) {
          console.log(`[upgrade][subscription] extra fields found`);
          return true;
        }
      }
      return false;
    }

    await auth.isAuthenticated()
      .then(
        await be.get("payment-jubilant","/subscriptions",false,true,true)
        .then((response) => {
          setLoading(false);
          console.log(response);
          if(response?.customerId){setCustomerId(response?.customerId)};
          if(checkForAdditionalFields(response, {customerId:null})){setView("manage");setSubscription(response);}
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        })
      )
      .catch(error => {
        setLoading(false);
        console.log(error);
      })
  }
  
 const sendAbandonedCartEvent = async (body) => {
    await auth.isAuthenticated()
      .then(
        await be.post("payment-jubilant","/payments/abandoned",body,true,true)
        .then((response) => {
          console.log("[upgrade][subscription] sent bandoned cart event: ", response);
        })
        .catch(error => {
          console.log(error);
        })
      )
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    initializePaddle(
      {
        // environment is 'sandbox' when testing, 'production' when live
        environment: (DEBUG ? 'sandbox' : 'production'),
        checkout: {
          settings: {
            displayMode:"overlay",
            theme:'light',
            locale:getUserLanguage()
          }
        },
        token: (DEBUG ? TEST_TOKEN : LIVE_TOKEN),
        eventCallback: async function(data) {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.closed":
              const status = data?.data?.status;
              if (status === "draft"){
                const body = {
                  productId: data?.data?.items[0]?.product?.id,
                  priceId: data?.data?.items[0]?.price_id
                }
                await sendAbandonedCartEvent(body);
              }
              break;
            case "checkout.completed":
              console.log("[upgrade] checkout completed, updating user profile...");
              setLoading(true)
              await sleep(5000)
              await auth.refresh();
              await getSubscriptions();
              break;
            default:
          }
        } 
      }).then(
        (paddleInstance) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        },
      );

    

    if(auth.sessionExist()){
      getSubscriptions();
    }

  }, []);

  const openCheckout = (product) => {
    const getSubscriptionPriceId = (_product) => {
      if(_product === 'premium'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_PREMIUM}
          if(pricingCadency === "yearly"){return TEST_YEARLY_PREMIUM}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_PREMIUM}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_PREMIUM}
        }
      }
      if(_product === 'professional'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_PROFESSIONAL}
          if(pricingCadency === "yearly"){return TEST_YEARLY_PROFESSIONAL}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_PROFESSIONAL}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_PROFESSIONAL}
        }
      }
      if(_product === 'agency-tier-1'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_AGENCY_TIER_1}
          if(pricingCadency === "yearly"){return TEST_YEARLY_AGENCY_TIER_1}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_AGENCY_TIER_1}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_AGENCY_TIER_1}
        }
      }
      if(_product === 'agency-tier-2'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_AGENCY_TIER_2}
          if(pricingCadency === "yearly"){return TEST_YEARLY_AGENCY_TIER_2}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_AGENCY_TIER_2}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_AGENCY_TIER_2}
        }
      }
      if(_product === 'agency-tier-3'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_AGENCY_TIER_3}
          if(pricingCadency === "yearly"){return TEST_YEARLY_AGENCY_TIER_3}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_AGENCY_TIER_3}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_AGENCY_TIER_3}
        }
      }
    }
    let checkoutObj = {
      items: [
        {
          priceId: getSubscriptionPriceId(product),
          quantity: 1
        }
      ],
      customData: {
        tolt_referral: window.tolt_referral
      }
    }
    if(customerId){
      createNestedObject(checkoutObj,["customer","id"]);
      checkoutObj['customer']['id'] = customerId;
    }else if(auth.getEmail()){
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = auth.getEmail();
    }else{
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  };

  return(
    <div className="container">


      {loading
        ? <Spinner style={{margin:'150px calc(50% - 16px)'}}  animation="border" size="lg"/>
        : 
        <>
          <Header view={view}/>
          {view === "upgrade"
              &&
                <Row className="my-5 mx-auto" style={{maxWidth:900}}>
                  <ToggleButtonGroup type="radio" name="pricing-cadency" className="my-4" value={pricingCadency} onChange={(value) => setPricingCadency(value)}>
                    <ToggleButton id="pricing-cadency-monthly" value="monthly" name="pricing-cadency" style={{borderRadius:'15px 0 0 15px'}} variant={pricingCadency === 'monthly' ? 'primary' : 'outline-primary'}>{t('upgrade:pricingCard.cadency.monthly')}</ToggleButton>
                    <ToggleButton id="pricing-cadency-yearly" value="yearly" name="pricing-cadency"  style={{borderRadius:'0 15px 15px 0'}}variant={pricingCadency === 'yearly' ? 'primary' : 'outline-primary'}>{t('upgrade:pricingCard.cadency.yearly')}</ToggleButton>
                  </ToggleButtonGroup>
                  <Col xs={12} md={6} className="p-0 my-2">
                    <PricingCard
                      background='var(--color-background-1)'
                      plan="premium"
                      currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                      price={pricingCadency === 'monthly' ? '5' : '2'}
                      hideCTA={!auth.sessionExist()}
                      CTALink={() => openCheckout('premium')}
                      features={{
                        bookings:true,
                        googleCalendar:true,
                        googleMeet:true,
                        eventTypes:true,
                        eventDuration:true,
                        emailNotifications:true,
                        reminders:true,
                        calendarConnections:true,
                        stripe:true,
                        zoom:true,
                        wix:true,
                        salesforza:true,
                        questions:true,
                        team:true,
                        embed:false,
                        zapier:false,
                        webhooks:false,
                        redirectionUrl:false,
                        timeslotHop:false,
                        bookingLimit:false,
                        notice:false,
                        lastAvailability:false,
                        branding:false,
                        eventApproval:false,
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-0 my-2">
                    <PricingCard
                      overlay
                      background='var(--color-background-1)'
                      plan="professional"
                      currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                      price={pricingCadency === 'monthly' ? '10' : '5'}
                      hideCTA={!auth.sessionExist()}
                      CTALink={() => openCheckout('professional')}
                      features={{
                        bookings:true,
                        googleCalendar:true,
                        googleMeet:true,
                        eventTypes:true,
                        eventDuration:true,
                        emailNotifications:true,
                        reminders:true,
                        calendarConnections:true,
                        stripe:true,
                        zoom:true,
                        wix:true,
                        salesforza:true,
                        questions:true,
                        team:true,
                        embed:true,
                        zapier:true,
                        webhooks:true,
                        redirectionUrl:true,
                        timeslotHop:true,
                        bookingLimit:true,
                        notice:true,
                        lastAvailability:true,
                        branding:true,
                        eventApproval:true,
                      }}
                    />
                  </Col>
                </Row>
          }
          {view === "agency" &&
            <Row className="my-5 mx-auto" style={{maxWidth:900}}>
              <LandingPage openCheckout={openCheckout}/>
            </Row>
          }
          {view === "manage" &&
            <Subscription subscription={subscription} setSubscriptionsLoading={setLoading} getSubscriptions={getSubscriptions}/>
          }
        </>
      }


    </div>
  );
}

export default Upgrade;
