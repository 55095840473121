import {useCreateBookingStore} from '../../store';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';
import './calendbook-calendar.css';
import './timezone-picker.css';
import Calendar from 'react-calendar';
import spacetime from 'spacetime'
import i18n from '../../i18n';

function DatePicker({username,eventType,mode,setBookingStep}){
  const [loadingInfo,setLoadingInfo] = useState(false);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [selectedMonth,setSelectedMonth] = useState(null);
  const storeCalendar = useCreateBookingStore(state => state.calendar);
  const setCalendar = useCreateBookingStore(state => state.setCalendar);
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setGuestTimezone = useCreateBookingStore(state => state.setGuestTimezone);
  const setGuestDay = useCreateBookingStore(state => state.setGuestDay);

  useEffect(() => {
    const getCalendarInfo = (_username,_eventType,_timezone) => {
      if(_username && _eventType && _timezone){
        const body = {
          ownerUsername: _username,
          eventKey: _eventType,
          timezone: _timezone
        }
        setLoadingInfo(true);
        be.get('jubilant-booking','/booking/calendar',body,false,true)
          .then(response => {
            console.info('[booking][getCalendarInfo] Success:');
            console.log(response);
            setInfoResponseStatus(200);
            setLoadingInfo(false);
            setCalendar(response);
            setGuestDay(spacetime(response?.availability.firstAvailableDate).format('iso.short'));
          })
          .catch(error => {
            console.info('[booking][getCalendarInfo] Error:');
            console.error(error);
            setInfoResponseStatus(error?.response?.status);
            setLoadingInfo(false);
            toast.error(`Error Fetching Calendar Availabilities`)
          })
      }else{
        console.info("[booking][getCalendarInfo] Data to send incomplete:")
        console.log("username: ",_username);
        console.log("event type: ",_eventType);
        console.log("timezone: ",_timezone);
      }
    }
    const timezoneToSend = spacetime.now().goto(storeGuest?.timezone?.value).timezone().name;
    if(mode !== "delete"){
      getCalendarInfo(username,eventType,timezoneToSend)
    } 
  },[setCalendar,setGuestTimezone,setGuestDay,username,eventType,storeGuest?.timezone?.value,mode,selectedMonth])

  const getUserLanguage = () => {
    return i18n.language.substring(0,2);
  }
  const getSelectedDate = (_date,_firstAvailableDate) => {
    if(_date && new Date(_date)){
      return new Date(_date);
    }else{
      return getMinDate(_firstAvailableDate);
    }
  }
  const getMinDate = (_firstAvailableDate) => {
    if(_firstAvailableDate && new Date (_firstAvailableDate)){
      return new Date(_firstAvailableDate);
    }else{
      return new Date();
    }
  }
  const getMaxDate = (_lastAvailableDate) => {
    if(_lastAvailableDate && new Date(_lastAvailableDate)){
      return new Date(_lastAvailableDate);
    }else{
      return null;
    }
  }
  const getDisabledDates = (_date,_week,_disabled) => {
    if(_disabled && _disabled?.months && _disabled?.months.length !== 0){
      if (_disabled?.months.some(month => spacetime(_date).format('iso-month') === month)){
        return true;
      }
    }

    if(_week !== undefined && _week !== null && Object.keys(_week).length !== 0){
      if (_week[spacetime(_date).format('day-short').toLowerCase()] === false){
        return true;
      }
    }
    if(_disabled && _disabled?.days && _disabled?.dayslength !== 0){
      if (_disabled?.days.some(day => spacetime(_date).format('iso-short') === day)){
        return true;
      }
    }
  }

  const firstAvailableDate = storeCalendar?.availability?.firstAvailableDate
  const lastAvailableDate = storeCalendar?.availability?.lastAvailableDate
  const weeklyAvailability = storeCalendar?.availability?.weekly?.weekDays
  const disabledDates = storeCalendar?.availability?.disabledDates

  return(
    <>
      {loadingInfo 
        ?
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spinner />
            </div>
            <Calendar
              tileDisabled={() => true}
              locale={getUserLanguage()}
              prev2Label={null}
              next2Label={null}
              minDetail="month"
            />
          </div>
        :
          <>
            {Object.keys(storeCalendar).length !== 0 &&
              <div className="d-inline ">
                <Calendar
                  minDate={getMinDate(firstAvailableDate)}
                  maxDate={getMaxDate(lastAvailableDate)}
                  value={getSelectedDate(storeGuest?.day,firstAvailableDate)}
                  onClickDay={(date) => {setGuestDay(spacetime(date).format('iso-short'));setBookingStep(3);}}
                  onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                    if(view === 'month'){
                      //setSelectedMonth(spacetime(activeStartDate).format('month'))
                      //setGuestDay(spacetime(activeStartDate).format('iso-short'))
                      console.log('Changed view to: ', activeStartDate, view)
                    }
                  }}
                  tileDisabled={({date, view}) => view === 'month' && getDisabledDates(date,weeklyAvailability,disabledDates)}
                  locale={getUserLanguage()}
                  prev2Label={null}
                  next2Label={null}
                  minDetail="month"
                />
              </div>
            }
          </>
      }
    </>
  );
}

export default DatePicker;
