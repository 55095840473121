import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import spacetime from 'spacetime';
import i18n from '../../../i18n';

function getUserLanguage(){
  return  i18n.language.substring(0,2);
}

function formatDate(date){
  // Date formatting options
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // Getting date object from db
  var dateFormatted = new Date(date);
  // Format: weekday, monthname daynumber, year
  dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
  // To uppercase string
  dateFormatted = dateFormatted.toString();

  return dateFormatted;
}

// TODO: Aggiungere durata dell'evento ex: 09:00 - 10:30
function DateTime(props){
  let time = spacetime.now()
  time = time.hour(props.selectedTime.split(':')[0])
  time = time.minute(props.selectedTime.split(':')[1])
  let displayTime = time.format('{time-24}')
  if(props.ampm){displayTime = time.format('{time}')}
  return(
    <>
      <div className="d-block-inline fw-bold text-success mt-2">
        {displayTime} - {formatDate(props.selectedDay)}
      </div>
    </>
  );
}

export default DateTime;
