import React from 'react';
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import be from '../BE';
import _OwnerInfo from './OwnerInfo/_OwnerInfo';
import _EventTypes from './EventTypes/_EventTypes';
import ReactGA from "react-ga4";

function _PublicEvents(props){
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Public Page" });
  const [eventTypes,setEventTypes] = React.useState({});
  const [ownerInfo,setOwnerInfo] = React.useState({});
  const [loading,setLoading] = React.useState(true);

  React.useEffect(() => {
    const getPublicEvents = async () => {
      await be.get("eventtypes","/public",{owner : props.match.params.user},false)
      .then((response) => {setEventTypes(response.eventTypes);setOwnerInfo(response.ownerInfo);setLoading(false)})
      .catch((response) => {props.history.push('/404')})
    }
    getPublicEvents()
  },[])

  return(
    <>
      <Container className="my-5">
          {loading
            ?
              <Spinner className="mx-auto" animation="border" variant="primary"/>
            :
              <Row>
                <Col md={4} className="my-3 text-center mx-auto">
                  <_OwnerInfo ownerInfo={ownerInfo}/>
                </Col>
                <Col md={8} className="my-3">
                    <_EventTypes eventTypes={eventTypes}/>
                </Col>
              </Row>
          }
      </Container>
    </>
  );
}

export default _PublicEvents;
