import React, {useState} from 'react';
import Proration from './Proration';
import Checkout from './Checkout';
import Button from 'react-bootstrap/Button';
import auth from '../Account/Auth';
import be from '../BE';

function UpgradeMembers({getTeamStatus,canUpgrade,viewerRole,selectedMembers,teamId}){
  const [showProrationModal, setShowProrationModal] = useState(false);
  const [prorationDetails, setProrationDetails] = useState(null); // New state for proration details
  const [loadingPreview,setLoadingPreview] = useState(false);
  const [showCheckout,setShowCheckout] = useState(false);

  const getMemberKeys = (members) => {
    return members.map(member => member.key);
  };

  const areAllFieldsPresent = (obj) => {
    return Object.values(obj).every(value => value !== null && value !== undefined);
  }

  const handleProrationModalClose = async () => {
    setShowProrationModal(false);
    setProrationDetails(null);
    await getTeamStatus();
  };

  async function upgradeMembers(_members){
    setLoadingPreview(true);
    let body = {
      teamId: teamId,
      members: getMemberKeys(_members),
      operation: "upgrade"
    }
    await auth.isAuthenticated()
      .then(async () => {
        await be.post("payment-jubilant","/team/members/preview",body,true,true,true)
          .then((response) => {
            if(response?.status === 204){
              setShowCheckout(true)              
            } else {
              const updateObj = {
                action: response?.data?.action,
                amount: response?.data?.amount,
                currency: response?.data?.currency_code,
                quantity: _members.length
              }
              if(areAllFieldsPresent(updateObj)){
                setProrationDetails(updateObj);
                setShowProrationModal(true);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
        setLoadingPreview(false);
      })
      .catch(()=>{})
  }

  const shouldDisable = () => {
    const areAllPlansFree = (members) => {
      // if the array is empty, we assume not all plans are free
      if (members.length === 0) return false;
      return members.every(member => member.plan === "free");
    };

    if(areAllPlansFree(selectedMembers)){
      if(canUpgrade){
        // enable upgrade if the flag canUpgrade is true
        return false;
      } else if (viewerRole !== "owner"){
        // disable if request is loading or who wants to upgrade is not owner
        return true;
      } else {
        // enable in other cases
        return false;
      }
    } else {
      // disable upgrade if selected members have different plans
      return true;
    }
  }

  const disabledBtn = shouldDisable();

  return(
    <>
      <Button
        disabled={disabledBtn || loadingPreview}
        size="sm"
        onClick={() => upgradeMembers(selectedMembers)}
        className="me-2"
      >
        Upgrade Selected {loadingPreview && "..."}
      </Button>

      <Proration
        show={showProrationModal}
        onHide={handleProrationModalClose}
        prorationDetails={prorationDetails}
        teamId={teamId}
        members={getMemberKeys(selectedMembers)}
        operation="upgrade"
      />

      <Checkout
        showCheckout={showCheckout}
        setShowCheckout={setShowCheckout}
        quantity={selectedMembers.length}
        getTeamStatus={getTeamStatus}
      />
    </>
  );
}

export default UpgradeMembers;
