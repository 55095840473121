import React from 'react';
import { withTranslation } from 'react-i18next';
import {Container,Spinner, Row, Col} from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'bootstrap/dist/css/bootstrap.min.css';
import {fadeInRight} from 'react-animations'
import styled, { keyframes } from 'styled-components';
import produce from 'immer';
import _Details from './Details/_Details';
import _DatePicker from './DatePicker/_DatePicker';
import _TimePicker from './TimePicker/_TimePicker';
import _Final from './Final/_Final';
import be from '../BE';
import auth from '../Account/Auth';
import spacetime from 'spacetime'
import * as dateTimeUtils from './dateTimeUtils.js';
import i18n from '../i18n';
// import Booking from '../Mocks/Booking.json';

const shortid = require('shortid');

const fadeAnimation = keyframes`${fadeInRight}`;
const Fade = styled.div`animation: .7s ${fadeAnimation};`;

function getUserTimezone(){
  if(auth.sessionExist()){ return auth.getTimezone() }
  else{ return Intl.DateTimeFormat().resolvedOptions().timeZone }
}
function getUserLanguage(){
  return  i18n.language.substring(0,2);
}
function isAmpm(){
  if(getUserLanguage() === "it"){return false}
  return true
}

function formatDate(date){
  // Date formatting options
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // Getting date object from db
  var dateFormatted = new Date(date);
  // Format: weekday, monthname daynumber, year
  dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
  // To uppercase string
  dateFormatted = dateFormatted.toString();

  return dateFormatted;
}

class _Booking extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      event : null,
      selectedDay : null,
      selectedRFCDay : null,
      RFCDateTimeGuest : null,
      RFCDateTimeOwner : null,
      selectedTime : null,
      selectedTimezone: getUserTimezone(),
      conflicts : null,
      loading : true,
      error: null,
      rescheduling : false,
      ampm : isAmpm(),
      submitting : false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.updateDay = this.updateDay.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.toggleAmpm = this.toggleAmpm.bind(this);
    this.updateTimezone = this.updateTimezone.bind(this);
    this.reselect = this.reselect.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(){
    const rescheduling = this.props.rescheduling
    // If I'm using this component to reschedule a booked event:
    if(rescheduling){
      this.setState({rescheduling : rescheduling})
      const bookingid = this.props.match.params.bookingid;
      const body = {bookingid : bookingid};

      auth.isAuthenticated()
      .then(() => {
        be.post('booking','/booked',body,true)
        .then(response => this.setState(produce(draft => {draft.event = response; draft.loading = false})))
        .catch(error => {
          if(error.response && error.response.status === 403){
            this.setState({error : 403, loading : false});
          }else{
            this.setState({error : 500, loading : false})
          }
        });
      })
      .catch(() => this.props.history.push('/login'));
    }else{
      // If I'm using this component to book a new event:
      const urlUser = this.props.match.params.user;
      const urlEvent = this.props.match.params.event;
      const body = { owner : urlUser, event : urlEvent }
      const surveyBody = { etype : urlEvent }

      be.post('booking','/info',body)
      .then(response => this.setState(produce(draft => {draft.event = response; draft.loading = false})))
      .catch(error => {
        if(error.response && error.response.status === 403){
          this.setState({error : 403, loading : false});
        }else{
          this.setState({error : 500, loading : false})
        }
      });
      
    }
  }

  updateDay(date){
    this.setState({
      selectedDay : dateTimeUtils.getStringDate(date),
      selectedRFCDay : dateTimeUtils.getRFC3339StringDate(date),
      conflicts: null,
    },

    () => {

      let body = {
        owner : this.state.event.details.ownerInfo.name,
        timeMin : this.state.selectedRFCDay,
        timeZone : this.state.selectedTimezone
      }

      be.post('booking','/conflicts',body,false)
      .then((response) => this.setState({conflicts : response}))
      .catch((response) => this.setState({conflicts : []}))
      
    })
  }

  updateTime(time){
    this.setState({selectedTime : time})
    let isoGuest = spacetime(this.state.selectedDay,this.state.selectedTimezone)
    isoGuest = isoGuest.time(time)
    this.setState({RFCDateTimeGuest:isoGuest.format('iso')})
    let isoOwner = isoGuest.clone()
    isoOwner = isoOwner.goto(this.state.event.details.ownerInfo.timezone)
    this.setState({RFCDateTimeOwner:isoOwner.format('iso')})
  }

  toggleAmpm(){
    this.setState({ampm : !this.state.ampm})
  }

  updateTimezone(timezone){
    this.setState({selectedTimezone : timezone})
  }

  reselect(){
    this.setState({selectedTime : ""})
  }

  async submit(guestData,surveyAnswers){
    this.setState({submitting : true})
    if(this.state.rescheduling){
      this.setState({submitting : true})
      const submitBody = {
       rfcdatetime : dateTimeUtils.dateFormat(new Date(this.state.selectedDay + ' ' +this.state.selectedTime)),
       RFCDateTimeGuest: this.state.RFCDateTimeGuest,
       RFCDateTimeOwner: this.state.RFCDateTimeOwner,
       bookingid : this.props.match.params.bookingid,
       guestTimezone : this.state.selectedTimezone
      }
      await auth.isAuthenticated()
      .then(() => {
        be.post('booking','/reschedule',submitBody,true)
        .then(() => this.setState({submitting : false}))
        .then(() => this.props.history.push(`/operations/confirm/${submitBody?.bookingid}`))})
      .catch(() => this.props.history.push('/login'));
    }else{
      const bookingId = shortid.generate()
      const submitBody = {
        owner : this.props.match.params.user,
        event : this.props.match.params.event,
        rfcdatetime : dateTimeUtils.dateFormat(new Date(this.state.selectedDay + ' ' +this.state.selectedTime)),
        RFCDateTimeGuest: this.state.RFCDateTimeGuest,
        RFCDateTimeOwner: this.state.RFCDateTimeOwner,
        guestname : guestData.name,
        guestmail : guestData.email,
        notes : guestData.notes,
        bookingid : bookingId,
        guestTimezone : this.state.selectedTimezone
      }
      const surveyBody = {
        etype : this.props.match.params.event,
        owner : this.props.match.params.user,
        bookingId : bookingId,
        answers : surveyAnswers
      }

      // mi serve per avere un true o false
      // per mandare al BE una richiesta con access (loggato)
      // o per mandare al BE una richesta senza access (guest)

      let authenticated
      await auth.isAuthenticated()
      .then((r) => (authenticated = r))
      .catch((r) => (authenticated = r))

      await be.post('booking','/booking',submitBody,authenticated,false,true)
      // dopo aver creato l'appuntamento torno alla home
      .then((response) => {
        // 201 response means this is a paid event, so i need to redirect
        // the guest to the provided payment link after the booking process
        if (response?.status === 201){
          window.location.href = response?.data?.state;
        // 200 response means this is a standard event, so i need to redirect
        // the guest to confirmation page, passing booking data as props
        } else if (response?.status === 200){
          this.props.history.push({
            pathname:`/operations/confirm/${bookingId}`,
            state:{
              owner:this.state.event.details.ownerInfo.fullName,
              event:this.state.event.details.eventInfo.name,
              description:this.state.event.details.eventInfo.description,
              datetime:this.state.RFCDateTimeGuest,
              duration:this.state.event.details.eventInfo.duration,
              timezone:this.state.selectedTimezone,
              redirectionUrl:this.state.event.details.eventInfo.redirectionUrl,
              location:this.state.event.details.eventInfo.location
            }
          })
        }
      }) 
      .catch(error => alert(error));

      await be.post('survey','/answer',surveyBody,authenticated)
      .then(() => this.setState({submitting : false})) 
      .catch(error => alert(error));
    }
  }

  render(){
    return(
      <>
        <Container className="mt-5 mb-5">
        {!this.state.loading
          ?
            this.state.error === null
            ?
              <>
                {this.state.rescheduling && <Row ><Col className="text-center my-3"><span className="h3">{this.props.t("rescheduling")} {formatDate(this.state.event.date)} - {this.state.ampm ? dateTimeUtils.toAmpm(this.state.event.time) : this.state.event.time}</span></Col></Row>}
                <Row>
                  <Col> <_Details state={this.state.event.details} isPaidEvent={this.state?.event?.details?.eventInfo?.isPaidEvent} paidEvent={this.state?.event?.details?.paidEvent}/> </Col>
                  {!this.state.selectedTime && <Col> <_DatePicker updateDay={this.updateDay} updateTimezone={this.updateTimezone} selectedDay={this.state.selectedDay} selectedTimezone={this.state.selectedTimezone} ownerTimezone={this.state.event.details.ownerInfo.timezone} ranges={this.state.event.availability.ranges} availability={this.state.event.availability.days}/> </Col>}
                  {this.state.selectedDay && !this.state.selectedTime && <Col><Fade> <_TimePicker callback={this.updateTime} selectedDay={this.state.selectedDay} enabledDays={this.state.event.availability.days} availability={this.state.event.availability.ranges} conflicts={this.state.conflicts} eventDuration={this.state.event.details.eventInfo.duration} ownerTimezone={this.state.event.details.ownerInfo.timezone} guestTimezone={this.state.selectedTimezone} ampm={this.state.ampm} toggleAmpm={this.toggleAmpm} prevBuffer={this.state.event.details.eventInfo.prevBuffer} nextBuffer={this.state.event.details.eventInfo.nextBuffer}/> </Fade></Col> }
                  {this.state.selectedDay && this.state.selectedTime  && <Col><Fade> <_Final submitting={this.state.submitting} reselect={this.reselect} selectedDay={this.state.selectedDay} selectedTime={this.state.selectedTime} submit={this.submit} reschedule={this.props.rescheduling} ampm={this.state.ampm} etype={this.props.match.params.event} owner={this.props.match.params.user} isPaidEvent={this.state?.event?.details?.eventInfo?.isPaidEvent}/></Fade></Col>}
                </Row>
              </>
            :
                        
              this.state.error === 403 && <SweetAlert danger style={{background:'var(--color-background-1)',color:'var(--color-text)'}} title={this.props.t("error.403.title")} onConfirm={() => this.props.history.push('/')}>{this.props.t("error.403.subtitle")}</SweetAlert>
              ||
              this.state.error === 500 && <SweetAlert danger style={{background:'var(--color-background-1)',color:'var(--color-text)'}} title={this.props.t("error.500.title")} onConfirm={() => this.props.history.push('/')}>{this.props.t("error.500.subtitle")}</SweetAlert>
          :
          <Row sm={12}>
            <Spinner className="mx-auto" animation="border" variant="primary" role="status"/>
          </Row>
        }
        </Container>
      </>
    );
  }
}

export default withTranslation('booking')(_Booking);
