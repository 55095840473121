import Form from 'react-bootstrap/Form';
import {useCreateBookingStore} from '../../../../../store';

function Field({option,type,questionId,fieldId,required}){
  const setRadioAnswer = useCreateBookingStore(state => state.setRadioAnswer);
  const setCheckboxAnswer = useCreateBookingStore(state => state.setCheckboxAnswer);

  const handleChange = (e,_fieldId,_type,_questionId) => {
    if(_type === 'radio'){
      setRadioAnswer(_fieldId,_questionId)
    } else if (_type === 'checkbox') {
      const isChecked = e.target.checked
      setCheckboxAnswer(_fieldId,isChecked,_questionId)
    }
  }

  return(
    <Form.Check 
      type={type}
      label={option}
      name={questionId}
      required={required}
      onChange={(e) => handleChange(e,fieldId,type,questionId)}
    /> 
  );
}

export default Field;
