import React, {useState} from 'react';
import {FormControl, Button,Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputGroup from 'react-bootstrap/InputGroup'
import be from '../../../BE';
import auth from '../../../Account/Auth';

function RedeemCode(){
  const { t } = useTranslation(['settings']);
  const [lifetimeCode,setLifetimeCode] = useState('');
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState(0);

  const handleChange = (event) => {
    setLifetimeCode(event.target.value);
  }

  async function redeemCode() {
    setProcessing(true);
    setStatus(0);
    await auth.isAuthenticated()
      .then(async () => {
        await be.post("payments","/redeem",{redeemCode : lifetimeCode},true)
          .then((r) => setStatus(200))
          .then(() => auth.refresh())
          .then(() => window.location.reload(false))
          .catch((e) => {setStatus(e?.response?.status)})
      })
      .catch(error => console.log(error))
    setProcessing(false);
  }

  return(
    <>
      <h4>{t('subscription.redeemCode.title')}</h4>
      <p>{t('subscription.redeemCode.subtitle')}</p>
      <InputGroup>
        <FormControl
          type="text"
          name="lifetime-code"
          id="lifetime-code-input"
          placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
          value={lifetimeCode}
          onChange={handleChange}
          maxLength={40}
          minLength={23}
        />
        <Button disabled={processing} onClick={redeemCode}>
          {processing 
            ? 
              <Spinner size="sm" animation="border" variant="light" /> 
              : 
              <i className="fas fa-long-arrow-alt-right"></i>
          }
        </Button>
      </InputGroup>
      {(status >= 404 && status <= 500) &&
        <p className="h6 text-danger">{t('subscription.redeemCode.error')}</p>
      }
      {(status === 400) &&
        <p className="h6 text-danger">{t('subscription.redeemCode.alreadyRedeemed')}</p>
      }
      {status === 200 &&
        <p className="h6 text-success">{t('subscription.redeemCode.success')}</p>
      }
    </>
  );
};

export default RedeemCode;
