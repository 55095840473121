import Form from 'react-bootstrap/Form';
import {useCreateBookingStore} from '../../../../store';

function TextAnswer({questionId,required}){
  const setTextAnswer = useCreateBookingStore(state => state.setTextAnswer);

  return(
    <Form.Control
      className="my-2"
      type="text"
      required={required}
      onChange={(e) => setTextAnswer(e.target.value,questionId)}
    />
  );
}

export default TextAnswer;
