import React, {useState} from 'react';
import UpgradePath from '../../Utilities/UpgradePath/UpgradePath';
import Card from 'react-bootstrap/Card';
import {useTranslation} from 'react-i18next';
import TimeFormat from './TimeFormat';
import WeekView from './WeekView/_WeekView';
import Hop from './Hop/Hop';
import BookingLimit from './BookingLimit/BookingLimit';
import Notice from './Notice/Notice';
import LastAvailable from './LastAvailable/LastAvailable';
import Approval from './Approval/Approval';
import Templates from './Templates/Templates';
import './availability.css';

function Availability () {
  const {t} = useTranslation(['eventCreator']);
  const [timeFormat,setTimeFormat] = useState(24)

  const toggleTimeFormat = () =>{
    timeFormat === 12 ? setTimeFormat(24) : setTimeFormat(12)
  }

  return (
    <>
      <Card style={{color:'var(--text-color)',background:"var(--color-background-1)"}} className="mb-2">

        <Card.Header className="h3">
          {t('availability.header')}
        </Card.Header>

        <Card.Body>
          {/*<TimeFormat timeFormat={timeFormat} toggleTimeFormat={toggleTimeFormat}/>*/}
          <Templates/>
          <WeekView timeFormat={timeFormat}/>
          <div className="advanced-rules-separator"><span>Advanced rules</span></div>
          <UpgradePath 
            featureName="event-timeslot-hop"
            position="right">
            <Hop/>
          </UpgradePath>
          <UpgradePath 
            featureName="event-booking-limit"
            position="right">
            <BookingLimit/>
          </UpgradePath>
          <UpgradePath 
            featureName="event-notice"
            position="right">
            <Notice/>
          </UpgradePath>
          <UpgradePath 
            featureName="event-last-availability"
            position="right">
            <LastAvailable/>
          </UpgradePath>
          <UpgradePath 
            featureName="event-approval"
            position="right">
            <Approval/>
          </UpgradePath>
        </Card.Body>

      </Card>
    </>
  );
}

export default Availability;
