import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';
import spacetime from 'spacetime'
import TimePickerHeader from './TimePickerHeader';
import TimeList from './TimeList.js';

// INPUT: Object con valori booleani per ogni giorno della settimana
//        (cominciano da lunedì)
// OUTPUT: Array con valori booleani
//         (Cominciano da domenica - Array[0] si riferisce a domenica)
// NOTE: Questa funzione è identica a quella in DatePicker, fare un refactoring
function parseAvailabilities(availabilityObj){
  var sorter = ['sun','mon','tue','wed','thu','fri','sat']
  let parsedDays = []
  sorter.map(day => {
    parsedDays.push(availabilityObj[day])
  })
  return parsedDays
}
// INPUT: selectedDay = stringa tipo "2020/05/18"
//        availabilityArray = array ottenuto da parseAvailabilities()
// OUTPUT: Array di Obj del tipo [{start : "08:00", end : "20:00"}, {start : "21:00", end : "22:00"}]
function getAvailabilityArray(selectedDay,availabilityArray){
  let selectedDayIndex = new Date(selectedDay).getDay();
  return availabilityArray[selectedDayIndex];
}

// INPUT: selectedDay = stringa tipo "2020/05/18"
//        availabilityArray = array ottenuto da parseAvailabilities()
// OUTPUT: Array di Obj del tipo [{start : "08:00", end : "20:00"}, {start : "21:00", end : "22:00"}]
// INFO: L'unica differenza con getAvailabilityArray è che usa spacetime per
//       andare di un giorno indietro e ricalcolare i range
function getPreviousAvailabilityArray(selectedDay,availabilityArray){
  let previousDay = spacetime(selectedDay).subtract(1,'day')
  let selectedDayIndex = previousDay.format('{day-number}')
  return availabilityArray[selectedDayIndex];
}

// INPUT: selectedDay = stringa tipo "2020/05/18"
//        availabilityArray = array ottenuto da parseAvailabilities()
// OUTPUT: Array di Obj del tipo [{start : "08:00", end : "20:00"}, {start : "21:00", end : "22:00"}]
// INFO: L'unica differenza con getAvailabilityArray è che usa spacetime per
//       andare di un giorno avanti e ricalcolare i range
function getNextAvailabilityArray(selectedDay,availabilityArray){
  let nextDay = spacetime(selectedDay).add(1,'day')
  let selectedDayIndex = nextDay.format('{day-number}')
  return availabilityArray[selectedDayIndex];
}

function _TimePicker(props){
  const componentStyle = {
    background:"var(--color-background-1)",
    height:400,
  }
const availabilityArray = getAvailabilityArray(props.selectedDay,parseAvailabilities(props.availability));
const previousAvailabilityArray = getPreviousAvailabilityArray(props.selectedDay,parseAvailabilities(props.availability));
const nextAvailabilityArray = getNextAvailabilityArray(props.selectedDay,parseAvailabilities(props.availability));
const prevBuffer = props.prevBuffer ? props.prevBuffer : "0"
const nextBuffer = props.nextBuffer ? props.nextBuffer : "0"
  return(
    <>
      <Card style={componentStyle}>
        <Card.Header className="text-center">
          <TimePickerHeader ampm={props.ampm} toggleAmpm={props.toggleAmpm}/>
        </Card.Header>
        <Card.Body>
          <TimeList
            selectedDay = {props.selectedDay}
            previousDay = {spacetime(props.selectedDay).subtract(1,'day').format('numeric-cn')}
            nextDay = {spacetime(props.selectedDay).add(1,'day').format('numeric-cn')}
            enabledDays = {props.enabledDays}
            availabilityArray={availabilityArray}
            previousAvailabilityArray={previousAvailabilityArray}
            nextAvailabilityArray={nextAvailabilityArray}
            conflictsArray={props.conflicts}
            previousConflictsArray={props.previousConflicts}
            nextConflictsArray={props.nextConflicts}
            eventDuration={props.eventDuration}
            ownerTimezone={props.ownerTimezone}
            guestTimezone={props.guestTimezone}
            prevBuffer={prevBuffer}
            nextBuffer={nextBuffer}
            callback={props.callback}
            ampm={props.ampm}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default _TimePicker;
