import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function Back(props){
  const { t } = useTranslation(['booking']);
  return(
    <>
      <Button variant="outline-secondary" onClick={props.reselect}> {t('final.backButton')} </Button>
    </>
  );
}

export default Back;
