import React from 'react';
import {useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const determineMinQuantity = (_unit) => {
  switch (_unit) {
    case 'minutes':
      return '5';
    case 'hours':
      return '1';
    case 'days':
      return '1';
    case 'weeks':
      return '1';
    default:
      return '0';
  }
}
const determineMaxQuantity = (_unit) => {
  switch (_unit) {
    case 'minutes':
      return '59';
    case 'hours':
      return '23';
    case 'days':
      return '30';
    case 'weeks':
      return '4';
    default:
      return '0';
  }
}

const getUnit = (_index) => { return document.getElementById(`reminder-unit-${_index}`)?.value }

function Reminders({index}){
  const {t} = useTranslation(['eventCreator']);
  const [minQuantity,setMinQuantity] = useState('0');
  const [maxQuantity,setMaxQuantity] = useState('0');
  const notifications = useCreateEventTypeStore(state => state.notifications);
  const setReminder = useCreateEventTypeStore((state) => state.setReminder);
  const deleteReminder = useCreateEventTypeStore((state) => state.deleteReminder);

  useEffect(()=>{
    // this check is used to convert old reminders structure to the new one.
    // useful for compatibility with old event types
    // the difference with the old structure is that 'minutes' inside 'unit' key
    //
    // OLD STRUCTURE:
    //  "reminders":  [{
    //    "method" : email | popup
    //    "minutes": int
    //  }]
    //
    // NEW STRUCTURE:
    //  "reminders":  [{
    //    "method" : email | popup | telegram
    //    "quantity" : int
    //    "unit" : weeks | days | hours | minutes
    //  }]
    if(notifications?.reminders[index]?.minutes){
      setReminder(index,{
        method:notifications?.reminders[index]?.method,
        quantity:notifications?.reminders[index]?.minutes,
        unit:"minutes"
      });
    }
    setMinQuantity(determineMinQuantity(getUnit(index)));
    setMaxQuantity(determineMaxQuantity(getUnit(index)));
  },[])

  return(
    <>
      <Row className="my-2">
        <Col xs={12} sm={3}  md={4} lg={4} className="mb-1">
          <Form.Select id={`reminder-method-${index}`} className="dark-field text-center" aria-label="Reminder Method" value={notifications?.reminders[index]?.method || "email"} onChange={(e) => setReminder(index,{method:e?.target?.value,quantity:document.getElementById(`reminder-quantity-${index}`)?.value,unit:document.getElementById(`reminder-unit-${index}`)?.value})}>
            <option value="email">email</option>
            <option value="popup">popup</option>
            <option value="telegram">telegram</option>
          </Form.Select>
        </Col>

        <Col xs={12} sm={2} md={2} lg={2} className="mb-1">
          <Form.Control
            id={`reminder-quantity-${index}`}
            className="dark-field"
            placeholder="quantity"
            min={minQuantity}
            max={maxQuantity}
            name="quantity"
            type="number"
            value={notifications?.reminders[index]?.quantity || minQuantity}
            onChange={(e) => setReminder(index,
            {
              method:document.getElementById(`reminder-method-${index}`)?.value,
              quantity:e?.target?.value,
              unit:document.getElementById(`reminder-unit-${index}`)?.value
            })}
          />
        </Col>

        <Col xs={9} sm={3} md={4} lg={4} className="mb-1">
          <Form.Select
            id={`reminder-unit-${index}`}
            className="dark-field text-center"
            aria-label="Reminder Unit"
            value={notifications?.reminders[index]?.unit || "minutes"}
            onChange={(e) => {
              setMinQuantity(determineMinQuantity(getUnit(index)));
              setMaxQuantity(determineMaxQuantity(getUnit(index)));
              setReminder(
                index,
                {
                  method:document.getElementById(`reminder-method-${index}`)?.value,
                  quantity:document.getElementById(`reminder-quantity-${index}`).value,
                  unit:e?.target?.value
                }
              );
            }}>
            <option value="minutes">minutes</option>
            <option value="hours">hours</option>
            <option value="days">days</option>
            <option value="weeks">weeks</option>
          </Form.Select>
        </Col>

        <Col xs={3} sm={2} md={2} lg={2} className="mx-auto mb-1">
          <div className="d-grid gap-2">
            <Button style={{border : "none", background : "transparent", color : "var(--colot-text)"}} onClick={(e) => {e.preventDefault();deleteReminder(index)}}>
              <i className="far fa-trash-alt my-auto"></i>
            </Button>
          </div>
        </Col>
      </Row>
    </>
);
}

export default Reminders;
