import React,{useContext,useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next'; 
import Toast from 'react-bootstrap/Toast';
import UserContext from '../UserContext'
import Header from './Header/Header';
import Event from './Event/Event';
import Redirect from './Redirect/Redirect';
import CTAButton from './CTAButton/CTAButton';
import be from '../BE';
import {isIframe} from '../Utilities/iframeUtils';
import ReactGA from "react-ga4";

function BookingConfirmation(props){
  const {t} = useTranslation(['booking']);
  const [bookingData,setBookingData] = useState(props.location.state);
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(0);
  const bookingId = props.match.params.bookingid
  const [user,setUser] = useContext(UserContext)
  const isIframePage = isIframe();

  useEffect(()=>{
    const getBookingData = () => {
      setError(0);
      setLoading(true);
      be.get('booking','/bookingConfirmation',{bookingId:bookingId})
        .then(response => setBookingData(response))
        .catch(error => setError(error?.response?.status))
      setLoading(false);
    }
    if(!bookingData){getBookingData()}
    ReactGA.send({ hitType: "pageview", page: "/operations/confirm", title: `Booking Confirmation (event ${bookingData?.deleted ? 'canceled' : 'scheduled'})` });
    try{window.tidioChatApi.hide()}catch{}
  },[])

  return(
    <div style={{maxWidth:1080}} className="my-4 my-md-5 mx-3 mx-lg-auto w-sm-100 w-lg-50">
      <Toast show={error !== 0}>
        <Toast.Header closeButton={false}>
          <strong className="me-auto">{t('confirmed.error.title')}</strong>
        </Toast.Header>
        <Toast.Body>
          {t('confirmed.error.subtitle')} {error}.
        </Toast.Body>
      </Toast>
      {loading && <>Loading...</>}
      {bookingData &&
        <>
          <Header owner={bookingData?.owner} deleted={bookingData?.deleted} needsApproval={bookingData?.needsApproval}/>
          <Event 
            name={bookingData?.event}
            description={bookingData?.description}
            datetime={bookingData?.datetime}
            duration={bookingData?.duration}
            timezone={bookingData?.timezone}
            location={bookingData?.location}
            isPaidEvent={bookingData?.isPaidEvent}
            paidEvent={bookingData?.paidEvent}
          />
          <Redirect url={bookingData?.redirectionUrl}/>
          <CTAButton
            redirect={bookingData?.redirectionUrl}
            loggedIn={user?.logged}
            isIframePage={isIframePage}
          />
        </>
      }
    </div>
  );
}

export default BookingConfirmation;
