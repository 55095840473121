import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DateTime from './DateTime';

// TODO: implementare timezone
// import Timezone from './Timezone'

function _Overview(props){
  return(
    <>
      <div className="d-inline-block mx-auto">
        <DateTime selectedDay={props.selectedDay} selectedTime={props.selectedTime} ampm={props.ampm} />
      </div>
    </>
  );
}

export default _Overview;
