import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OwnerPic from './OwnerPic';
import OwnerName from './OwnerName';



function OwnerInfo(props){
  return(
    <>
      <div  className="text-center"> <OwnerPic state={props.state.pic}/> </div>
      <div  className="text-center"> <OwnerName state={props.state.fullName}/> </div>

    </>
  );
}

export default OwnerInfo;
