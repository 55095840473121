import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ListGroup,Spinner} from 'react-bootstrap';
import TimeSlot from './TimeSlot';
import { useTranslation } from 'react-i18next';
import spacetime from 'spacetime'
import * as dateTimeUtils from '../dateTimeUtils.js';

// INPUT: <start> String, <end> String, <duration> (minutes), <timeSpan> (minutes) String, <day> String
// OUTPUT: <timeSlots> an array of timeslots between start and end
function createRange(start,end,eventDuration,prevBuffer,nextBuffer,day){
  var timeSlots = [];
  var duration = Number(eventDuration);
  prevBuffer = Number(prevBuffer);
  nextBuffer = Number(nextBuffer);
  var mStart = dateTimeUtils.getMillisecondsFromString(start);
  var mEnd = dateTimeUtils.getMillisecondsFromString(end);
  var current = dateTimeUtils.getMillisecondsFromString(new Date().getHours().toString() + ":" + new Date().getMinutes().toString())
  var mIterator = mStart;
  // add time slots until the end of the range
  while (mIterator <= mEnd){
    // if displaying current day time slots
    if(day === dateTimeUtils.getCurrentDateString()){
      // time slots must be greater than current time
      if(mIterator >= current && mIterator <= dateTimeUtils.addMinutes(mEnd, duration)){
        timeSlots.push(dateTimeUtils.getStringFromMilliseconds(mIterator));
      }
    // if displaying other days just put every time slot in the range
    }else{
      if(dateTimeUtils.addMinutes(mIterator,duration) <= mEnd){
        timeSlots.push(dateTimeUtils.getStringFromMilliseconds(mIterator));
      }
    }
    mIterator = dateTimeUtils.addMinutes(mIterator,15);
  }
  return timeSlots;
}

// remove conflicts from array of timeslots
// INPUT:
//  timeslots: array of strings with timeslots ["09:00","09:15", ...]
//  conflicts: array of array of objects with conflicts from gcal
//    [[{"start":"2022-03-05T01:30:00+01:00","end":"2022-03-05T02:30:00+01:00"},{...}],[{...}]
//  eventDuration: string with event duration in minutes "15"
//  prevBuffer: string with buffer time before timeslot in minutes "10"
//  nextBuffer: string with buffer time after timeslot in minutes "10"
// OUTPUT:
//  result: array of strings with timeslots without conflicts
function removeConflicts(timeslots, conflicts, eventDuration, prevBuffer, nextBuffer){
  let result = timeslots
  // convert to Number to make calculations
  eventDuration = Number(eventDuration)
  prevBuffer = Number(prevBuffer)
  nextBuffer = Number(nextBuffer)
  // per ogni intervallo start - end dei conflitti:
  // - rimuovo da start duration + timespan (per distaccarmi dallo slot precedente)
  // - aggiungo ad end timespan (per distaccarmi dallo slot successivo)
  // - rimuovo dai ranges tutti gli slot tra start ed end shiftati di timespan/duration
  if(conflicts && conflicts.length > 0){
    for (let i = 0; i < conflicts.length; i++) {
      let start = dateTimeUtils.getMillisecondsFromRFCString(conflicts[i].start)
      let end = dateTimeUtils.getMillisecondsFromRFCString(conflicts[i].end)
      let shiftedStart = dateTimeUtils.removeMinutes(start, prevBuffer + eventDuration)
      let shiftedEnd = dateTimeUtils.addMinutes(end, nextBuffer)
      // per ogni range
      console.log('analyzing conflict: ' + conflicts[i].start + ' - ' + conflicts[i].end)
      for (let k = 0; result && k < result.length; k++) {
        // per ogni slot che trovo in un range
        let slot = dateTimeUtils.getMillisecondsFromString(result[k])
        // se lo slot è compreso tra start e end del conflitto
        if(slot > shiftedStart && slot <= shiftedEnd){
          //lo rimuovo
          result = arrayRemove(result,result[k])
          console.log('removed: ' + result[k])
          k--
        }
      }
    }
  }else{
    result = timeslots
  }
  return result;
}

function arrayRemove(arr, value) {
  return arr.filter(function(ele){
    return ele !== value;
  });
}

// timezone shifter to use before removing conflicts
// INPUT:
//  timeSlots: array of strings with timeslots ["09:00", "09:15", ...]
//  day: string with selected date "2022//01/01" or "2022-01-01"
//  enabledDays: object with boolean vales for enabled weekdays {mon:true,tue:false,wed:false, ...}
//  guestTimezone: string with guest timezone "Asia/Colombo"
//  ownerTimezone: string with owner timezone "Asia/Colombo"
// OUTPUT:
//  result: array of strings with shifted timeslots strings ["10:00", "10:15", ...]
function shiftToGuestTimezone(timeSlots,day,enabledDays,guestTimezone,ownerTimezone){
  let result = []
  let weekDay = spacetime(day).format('{day-short}').toLowerCase()
  let isWeekDayEnabled = enabledDays[weekDay]

  if(isWeekDayEnabled){
    let owner = spacetime(day,ownerTimezone) 
    for(let range of timeSlots){
      for(let slot of range){
        owner = owner.time(slot)
        let guest = owner.clone()
        guest = guest.goto(guestTimezone)
        if(guest.day() === owner.day()){
          result.push(guest.format('time-24'))
        }
      }
    }
  }

  return result;
}

function TimeList(props){
  const { t } = useTranslation(['booking']);
  var ranges = []
  var shiftedRanges = []

  // per ogni range Obj nell'array delle disponibilità
  for (var range in props.availabilityArray) {
    let start = props.availabilityArray[range].start
    let end = props.availabilityArray[range].end
    // crea in ranges dei range tra start ed end usando la durata dell'etype e il timeSpan
    ranges.push(createRange(start,end,props.eventDuration,props.prevBuffer,props.nextBuffer,props.selectedDay))
  }

  // shifting timezones
  shiftedRanges = shiftToGuestTimezone(ranges,props.selectedDay,props.enabledDays,props.guestTimezone,props.ownerTimezone)
 
  // removing conflicts
  if(props.conflictsArray && props.conflictsArray.length > 0){
    for(let conflict of props.conflictsArray){
      shiftedRanges = removeConflicts(shiftedRanges, conflict, props.eventDuration,props.prevBuffer, props.nextBuffer)
    }
  }


  return(
      <div className="time-picker">
        {props.conflictsArray !== null
          ?
            shiftedRanges.map((time)=>(
              <ListGroup style={{width : "200px"}}>
                <TimeSlot key={time + "-timeslot"} time={time} ampm={props.ampm} callback={props.callback}/>
              </ListGroup>
            ))
          :
            <Spinner animation="border" variant="primary" role="status"/>
        }
        {shiftedRanges.length === 0 && <> {t('time.empty')}</>}
      </div>
  );
}

export default TimeList;
