import getSymbolFromCurrency from 'currency-symbol-map';

const frontendFormatter = (number) => {
    console.info('[booking][payment] parsing be price:');
    let result = '';
    if(number !== undefined){
      result = Number(number)/100;
    }
    console.info(result);
    return result;
  };

function Price ({isPaidEvent,paidEvent}) {
  return(
    <>
      {isPaidEvent && paidEvent?.amount &&
        <div className=" d-flex align-items-center">
          <i class="fa-solid fa-credit-card me-1"></i>
          {paidEvent?.currency && getSymbolFromCurrency(paidEvent?.currency) !== undefined && getSymbolFromCurrency(paidEvent?.currency)} {frontendFormatter(paidEvent?.amount)}
          <i className="fa-brands fa-cc-visa mx-1"></i>
          <i className="fa-brands fa-cc-mastercard mx-1"></i>
          <i className="fa-brands fa-cc-amex mx-1"></i>
        </div>
      }
    </>
  );
}

export default Price;
