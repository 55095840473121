import React from 'react';
import {TermsEng} from './terms-eng';
import ReactGA from "react-ga4";

function Terms(){
  ReactGA.send({ hitType: "pageview", page: "/terms", title: "Terms" });
  try{window.tidioChatApi.show()}catch{}
  return(
    <div style={{margin:'50px'}}>
      <pre style={{
          'white-space':'pre-wrap',
          'white-space':'-moz-pre-wrap',
          'white-space':'-o-pre-wrap',
          'white-space':'-pre-wrap',
          'word-wrap':'break-word',
          'white-space':'break-spaces',
          'overflow-x':'auto',
        }}>
        {TermsEng} 
      </pre>
    </div>
  );
}

export default Terms;
