import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from 'react-bootstrap';
import spacetime from 'spacetime';

function TimeSlot({time,callback,ampm}){
  const componentStyle={
    background:"var(--color-background-2)",
    fontWeight:"bold",
    color:"var(--color-text)",
    borderColor:"var(--color-border)"
  }
  let hours = time.split(':')[0];
  let minutes = time.split(':')[1];
  let now = spacetime.now();
  now = now.hour(hours)
  now = now.minute(minutes)
  let displayTime = now.format('{time-24}')
  
  if(ampm){displayTime = now.format('{time}')}

  return(
    <>
      <p key={time + "-wrapper"} className="d-grid gap-2">
        <Button style={componentStyle} onClick={() => {callback(time)}} key={time}> {displayTime} </Button>
      </p>
    </>
  );
}

export default TimeSlot;
