import React from 'react';
import {RefundIta} from './refund-ita';
import {RefundEng} from './refund-eng';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

function Refund(){
  ReactGA.send({ hitType: "pageview", page: "/refund", title: "Refund" });
  try{window.tidioChatApi.show()}catch{}
  const { i18n } = useTranslation();
  return(
    <div style={{margin:'50px'}}>
      <pre style={{
          'white-space':'pre-wrap',
          'white-space':'-moz-pre-wrap',
          'white-space':'-o-pre-wrap',
          'white-space':'-pre-wrap',
          'word-wrap':'break-word',
          'white-space':'break-spaces',
          'overflow-x':'auto',
        }}>
        
        {i18n.language.substring(0,2) === 'it'
          ?
            RefundIta
          :
            RefundEng
        }

      </pre>
    </div>
  );
}

export default Refund;
