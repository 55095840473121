import React from 'react';
import { useCreateBookingStore } from '../../store';
import { Translation } from 'react-i18next';
import UserContext from '../../UserContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form, Row, Col, Button} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Login from '../../Login';
import auth from '../../Account/Auth';
import Surveys from './Surveys/_Surveys';
// import Signin from '../../Mocks/Signin.json';

class FinalForm extends React.Component{
  static contextType = UserContext
  constructor(props){
    super(props);
    this.state = {
      name : "",
      email : "",
      notes : "",
      logged : "not_ready"
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  handleChange(event){
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const partialState = {};
    partialState[name] = value;
    this.setState(partialState);
  }

  handleSubmit(event){
    event.preventDefault();
    // getting zustand state for survey answers (functional subcomponent)
    const surveyAnswers = useCreateBookingStore.getState().surveyAnswers
    // sending current state and survey obj to parent
    this.props.submit(this.state,surveyAnswers);
  }

  componentDidMount(){
    // carico il context
    const [user,setUser] = this.context
    // Controllo se l'utente è loggato
    auth.isAuthenticated()
    .then(() => {
      // se è loggato aggiorno lo stato di questo componente
      // e lo stato logged del context (per re-rendering status navbar)
      this.setState({logged:true})
      setUser({logged : true})
    })
    .catch(() => {this.setState({logged:false})})
  }


  render(){
    var loggedUser = "";
    if(this.state.logged === true){
      loggedUser = auth.getFullName()
    }

    return(
      <>
        <Form onSubmit={this.handleSubmit}>
          {this.state.logged === true
            ?
              <>
                {this.props.reschedule
                  ? null
                  :
                    <Translation ns="booking">
                      {
                        (t) => <>{loggedUser}, { t('final.loggedMessage') }</>
                      }
                    </Translation>
                }
              </>
            :
            <Row>
            <Col lg={12}>
            <Row>
              <Col sm={6} className="mb-0 mb-sm-3">
                <Login callback={this.componentDidMount}/>
              </Col>
              <Col className="my-3 mt-sm-2 py-auto ">
                <Form.Label className="m-0">
                  <Translation ns="booking">
                    {
                      (t) => <>{ t('final.info') }</>
                    }
                  </Translation>
                </Form.Label>
              </Col>
            </Row>
            </Col>
              <Col md={12} lg={6}>
                <Form.Label>
                  <Translation ns="booking">
                    {
                      (t) => <>{ t('final.name') }</>
                    }
                  </Translation>
                </Form.Label>
                <Form.Control name="name" onChange={this.handleChange} required/>
              </Col>
              <Col md={12} lg={6} className="mt-sm-2 mt-lg-0">
                <Form.Label>
                  <Translation ns="booking">
                    {
                      (t) => <>{ t('final.email') }</>
                    }
                  </Translation>
                </Form.Label>
                <Form.Control type="email" name="email" onChange={this.handleChange} required/>
              </Col>
            </Row >
          }


          {this.props.reschedule
            ?
              <Button style={{minWidth:200,minHeight:38}} className="d-flex justify-content-center mx-auto mt-3" type='submit' disabled={this.props.submitting}>
                {this.props.submitting
                  ?
                    <Spinner animation="border" size="sm" className="my-auto"/>
                  :
                    <Translation ns="booking">
                      {
                        (t) => <>{ t('final.rescheduleButton') }</>
                      }
                    </Translation>
                }
              </Button>
            :
              <>
                <Row className="mt-3">
                  <Col>
                    <Surveys etype={this.props.etype} owner={this.props.owner}/>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>
                      <Translation ns="booking">
                        {
                          (t) => <>{ t('final.notes') }</>
                        }
                      </Translation>
                    </Form.Label>

                    <Translation ns="booking">
                      {
                        (t) => <Form.Control as="textarea" rows="2" name="notes" placeholder={t("final.notesPlaceholder")} onChange={this.handleChange}/>
                      }
                    </Translation>

                  </Col>
                </Row>

                {this.props.isPaidEvent &&
                  <p>
                    <Translation ns="booking">
                      {
                        (t) => <>{ t('final.paidEvent.termsText') }</>
                      }
                    </Translation>
                    &nbsp;
                    <a href="/stripe-terms" target="_blank">
                      <Translation ns="booking">
                        {
                          (t) => <>{ t('final.paidEvent.termsLink') }</>
                        }
                      </Translation>
                    </a>
                  </p>
                }

                <Button
                  style={{minWidth:200,minHeight:38}}
                  className="mt-3 d-flex justify-content-center mx-auto"
                  type='submit'
                  disabled={this.props.submitting}
                >
                  {this.props.submitting
                    ?
                      <Spinner animation="border" size="sm" className="my-auto"/>
                    :
                      <Translation ns="booking">
                        {
                          (t) => <>{ t('final.bookButton') }</>
                        }
                      </Translation>
                  }
                </Button>
              </>
          }
        </Form>
      </>
    );
  }
}

export default FinalForm;
