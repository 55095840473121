import spacetime from 'spacetime';

export function toAmpm(time){
  let hours = time.split(':')[0];
  let minutes = time.split(':')[1];
  let now = spacetime.now();
  now = now.hour(hours)
  now = now.minute(minutes)
  let displayTime = now.format('{time}')
  return displayTime
}

// INPUT: Data in formato Javascript Date Object
// OUTPUT: Data in formato YYYY/MM/DD (stringa)
export function getStringDate(date){
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [date.getFullYear(),
    (mm>9 ? '' : '0') + mm,
    (dd>9 ? '' : '0') + dd
  ].join('/');
}

// INPUT: Data in formato Javascript Date Object
// OUTPUT: Data in formato RFC3339 con orario 00:00:00 (stringa)
export function getRFC3339StringDate(d){
 function pad(n){return n<10 ? '0'+n : n}
 return d.getFullYear()+'-'
      + pad(d.getMonth()+1)+'-'
      + pad(d.getDate())+'T'
      + '00:00:00Z'
}

// INPUT: Data in formato Javascript Date Object
// OUTPUT: Data in formato RFC3339 con orario passato da Date
export function dateFormat(date)
{
  var dd = date.getDate();
  var mm = date.getMonth()+1;
  var yyyy = date.getFullYear();

  var hour= date.getHours();
  var minutes=date.getMinutes();
  var seconds=date.getSeconds();

  if (hour<10){
    hour='0'+hour;
  }
  if (minutes<10){
    minutes='0'+minutes;
  }
  if (seconds<10){
    seconds='0'+seconds;
  }

  if(dd<10){
    dd='0'+dd
  }
  if(mm<10){
    mm='0'+mm
  }

  var n = date.getTimezoneOffset()/60;
  var offset="";

  if (n>0){
    offset="-";
  }
  else{
    offset="+"
    n=(-1)*n;
  }

  if (n<9){
    offset+="0"+n;
  }
  else{
    offset+=n;
  }

  var result = yyyy + '-' + mm + '-' + dd + "T" + hour + ":" + minutes + ":" + seconds + offset + ":00";

  return result;
}

export function getHoursString(timeString){
  return timeString.split(':')[0];
}

export function getMinutesString(timeString){
  return timeString.split(':')[1];
}

export function getHoursInt(timeString){
  return Number(timeString.split(':')[0]);
}

export function getMinutesInt(timeString){
  return Number(timeString.split(':')[1]);

}

export function appendLeadingZeroes(n){
  return (n<=9 ? ("0" + n) : n);
}

// OUTPUT: date string like '2020/06/21'
export function getCurrentDateString(){
  let d = new Date()
  return (
    appendLeadingZeroes(d.getFullYear()) + '/' +
    appendLeadingZeroes(d.getMonth() + 1 ) + '/' +
    appendLeadingZeroes(d.getDate())
  )
}

// INPUT: RFCString like '2020-08-21T13:00:00+02:00'
// OUTPUT: milliseconds indicating only TIME from RFCString
export function getMillisecondsFromRFCString(RFCString){
  // getting only time substring like '13:00'
  let timeString = RFCString.substring(11,16);
  return getMillisecondsFromString(timeString);
}

// INPUT: milliseconds from Date.getTime()
// OUTPUT: time string
export function getStringFromMilliseconds(milliseconds){
  let date = new Date(milliseconds);
  return appendLeadingZeroes(date.getHours()) + ':' + appendLeadingZeroes(date.getMinutes());
}

// INPUT:   time string like '08:00'
// OUTPUT:  milliseconds with new time set
export function getMillisecondsFromString(timeString){
  let time = new Date();
  time.setHours(getHoursInt(timeString),getMinutesInt(timeString),0);
  return time.getTime();
}

// INPUT:   milliseconds Date, hoursSpan Int
// OUTPUT:  milliseconds Date with hours shifted by hoursSpan
export function addHours(milliseconds,hoursSpan){
  let time = new Date(milliseconds);
  time.setHours((time.getHours() + hoursSpan));
  return time.getTime();
}

// INPUT:   milliseconds Date, minutesSpan Int
// OUTPUT:  milliseconds Date with minutes shifted by minutesSpan
export function addMinutes(milliseconds,minutesSpan){
  let time = new Date(milliseconds);
  time.setHours(time.getHours(),(time.getMinutes() + minutesSpan));
  return time.getTime();
}

// INPUT:   milliseconds Date, minutesSpan Int
// OUTPUT:  milliseconds Date with minutes shifted by minutesSpan
export function removeMinutes(milliseconds,minutesSpan){
  let time = new Date(milliseconds);
  time.setHours(time.getHours(),(time.getMinutes() - minutesSpan));
  return time.getTime();
}
