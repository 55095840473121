import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import {Container, Row, Col, Button, Spinner, Tab, Tabs} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MediaQuery from 'react-responsive';
import ViewToggler from './ViewToggler/_ViewToggler';
import EventTypeContainer from './EventTypeContainer/_EventTypeContainer';
import EventContainer from './EventContainer/_EventContainer';
import Checklist from './Checklist/_Checklist.js';
import auth from '../Account/Auth';
import be from '../BE';
import ReactGA from "react-ga4";
import UpgradePath from '../Utilities/UpgradePath/UpgradePath';

export class _Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      eventTypes : {},
      upcomings : {},
      pendingUpcomings : {},
      pendingUpcomingsLength : 0,
      checklist : {},
      connections : {},
      activeView : 'eventTypes',
      isMobile: false,
      loadingEventTypes : true,
      loadingUpcomings : true,
      loadingChecklist : true,
      loadingConnections : true,
      activeTab: 'upcoming',
    };
    this.toggleView = this.toggleView.bind(this);
  }

  // deve essere un caricamento asincrono, mentre aspetto la risposta
  // dal BE continuo a rederizzare altro, async è d'obbligo
  async componentDidMount(){
    ReactGA.send({ hitType: "pageview", page: "/", title: "Dashboard" });
    try{window.tidioChatApi.show()}catch{}
    // devo usare await nella chiamata ad isAuthenticated perchè nel caso di
    // richiesta di refresh devo aspettare i nuovi token prima di poter
    // effettuare altre chiamate
    await auth.isAuthenticated()
      .then(() => {
        // get Event Types
        be.get('eventtypes','/eventypes','',true)
          .then(response => {this.setState({eventTypes : response, loadingEventTypes : false})})
          .catch(error => {console.log(error); this.setState({loadingEventTypes : false})});
        // get Upcomings
        be.get('booking','/upcoming','',true)
          .then(response => {this.setState({upcomings : response?.upcomings, pendingUpcomings: response?.pendingUpcomings , pendingUpcomingsLength: response?.meta?.pendingUpcomings?.count || 0,loadingUpcomings : false})})
          .catch(error => {console.log(error); this.setState({loadingUpcomings : false})});
        // get Checklist
        be.get('todo','/getTaskList','',true)
          .then(response => {this.setState({checklist : response, loadingChecklist : false})})
          .catch(error => {console.log(error); this.setState({loadingChecklist : false})});
        // get Calendar Connections
        be.get('calendarhandler','/getCalendars','',true)
          .then(response => {this.setState({connections : response, loadingConnections : false})})
          .catch(error => {console.log(error); this.setState({loadingConnections : false})});
      })
    // redirect istantaneo alla pagina login se non sono autenticato
      .catch(() => this.props.history.push('/login'));
  }

  toggleView = (view) => { this.setState({activeView:view}) }

  render(){
    var EventTypes = this.state?.eventTypes;
    var Upcomings = this.state?.upcomings;
    var PendingUpcomings = this.state?.pendingUpcomings;
    var PendingUpcomingsLength = this.state?.pendingUpcomingsLength;
    var Connections = this.state?.connections;
    return (
      <>
        <Container fluid className="mt-5 px-2 px-md-5">
          {/*Mobile or Tablet device*/}
          <MediaQuery maxWidth={991}>

            {/*View toggler radio buttons (styled)*/}
            <ViewToggler activeView={this.state.activeView} toggleView={this.toggleView} /> 

            {this.state.activeView === 'upcomings' &&
            <Row>

              {/*Upcomings container----------*/}
              <Col lg={6} id='upcomings-zone'>
                {!this.state.loadingChecklist && 
                <Checklist checklist={this.state.checklist}/>
                }
                <Tabs
                  activeKey={this.state.activeTab}
                  onSelect={(k) => this.setState({ activeTab: k })}
                  className="mb-3"
                >
                  <Tab eventKey="upcoming" title="Upcoming">
                    {this.state.loadingUpcomings
                      ?
                        <Spinner className="mx-auto" animation="border" variant="primary"/>
                        :
                        <EventContainer state={Upcomings} />
                    }
                  </Tab>
                  <Tab eventKey="pending" title={`Pending (${PendingUpcomingsLength})`}>
                    {this.state.loadingUpcomings
                      ?
                        <Spinner className="mx-auto" animation="border" variant="primary"/>
                        :
                        <>{PendingUpcomings && <EventContainer pending state={PendingUpcomings}/>}</>
                    }
                  </Tab>
                </Tabs>
              </Col>

            </Row>
            }
            {this.state.activeView === 'eventTypes' &&
              <Row>
                {/*Event Types container*/}
                <Col lg={6} id='event-type-zone'>
                  {/*New event type button*/}
                  <Row>
                    <Col className="my-3 text-center">
                      {Object.keys(EventTypes).length === 0
                        ?
                          <Button 
                            id='event-type-creator'
                            variant="primary"
                            onClick={() => this.props.history.push('/eventcreator')}
                            className=""
                            style={{borderRadius:25}}
                            block
                          >
                            <i className="fas fa-plus"></i>
                            <Translation ns="home">
                              { (t) => <span className="ms-2">{ t('eventTypes.new') }</span> }
                            </Translation>
                          </Button> 
                        :
                          <UpgradePath 
                            featureName="unlimited-event-types"
                            position="right"
                          >
                            <Button 
                              id='event-type-creator'
                              variant="primary"
                              onClick={() => this.props.history.push('/eventcreator')}
                              className=""
                              style={{borderRadius:25}}
                              block
                            >
                              <i className="fas fa-plus"></i>
                              <Translation ns="home">
                                { (t) => <span className="ms-2">{ t('eventTypes.new') }</span> }
                              </Translation>
                            </Button>
                          </UpgradePath>
                      }
                    </Col>
                  </Row>
                  {/*Event types*/}
                  <Row className="d-flex flex-wrap justify-content-center">
                    {this.state.loadingEventTypes || this.state.loadingConnections
                      ?
                        <Spinner className="mx-auto" animation="border" variant="primary"/>
                        :
                        <EventTypeContainer state={EventTypes} connections={Connections}/>
                    }
                  </Row>
                </Col>
              </Row>
            }
          </MediaQuery>

          {/*Desktop device*/}
          <MediaQuery minWidth={992}>
            {/*First Row for section titles----------*/}
            <Row>
              {/*Event Types title*/}
              {Object.keys(EventTypes).length === 0
                ?
                  <Col lg={6} id='event-types-title'>
                    <Translation ns="home">
                      { (t) => <span className="h3">{ t('eventTypes.title') }</span> }
                    </Translation>
                    <Button 
                      id='event-type-creator'
                      variant="primary"
                      onClick={() => this.props.history.push('/eventcreator')}
                      className="ms-3 mb-2"
                    >
                      <i className="fas fa-plus"></i>
                    </Button> 
                  </Col>
                  :
                  <UpgradePath 
                    featureName="unlimited-event-types"
                    position="right"
                    customWidth="50%"
                  >
                    <Col lg={6} id='event-types-title'>
                      <Translation ns="home">
                        { (t) => <span className="h3">{ t('eventTypes.title') }</span> }
                      </Translation>
                      <Button 
                        id='event-type-creator'
                        variant="primary"
                        onClick={() => this.props.history.push('/eventcreator')}
                        className="ms-3 mb-2"
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </UpgradePath>
              }
              {/*Upcomings title*/}
              <Col lg={6} id='upcomings-title'>
                <Translation ns="home">
                  { (t) => <span className="h3">{ t('upcomings.title') }</span> }
                </Translation>
              </Col>
            </Row>

            <Row>
              {/*Event Types container----------*/}
              <Col lg={6} id='event-type-zone'>
                <Row className="d-flex flex-wrap justify-content-center">
                  {this.state.loadingEventTypes || this.state.loadingConnections
                    ?
                      <Spinner className="mx-auto" animation="border" variant="primary"/>
                      :
                      <EventTypeContainer state={EventTypes} connections={Connections}/>
                  }
                </Row>
              </Col>

              {/*Upcomings container----------*/}
              <Col lg={6} id='upcomings-zone'>
                {!this.state.loadingChecklist && 
                <Checklist checklist={this.state.checklist}/>
                }
                <Tabs
                  activeKey={this.state.activeTab}
                  onSelect={(k) => this.setState({ activeTab: k })}
                  className="mb-3"
                >
                  <Tab eventKey="upcoming" title="Upcoming">
                    {this.state.loadingUpcomings
                      ?
                        <Spinner className="mx-auto" animation="border" variant="primary"/>
                        :
                        <EventContainer state={Upcomings} />
                    }
                  </Tab>
                  <Tab eventKey="pending" title={`Pending (${PendingUpcomingsLength})`}>
                    {this.state.loadingUpcomings
                      ?
                        <Spinner className="mx-auto" animation="border" variant="primary"/>
                        :
                        <>{PendingUpcomings && <EventContainer pending state={PendingUpcomings}/>}</>
                    }
                  </Tab>
                </Tabs>
              </Col>

            </Row>
          </MediaQuery>
        </Container>
      </>
    );
  }
}

export default _Home;
