import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

function DatePickerHeader(props){
  const { t } = useTranslation(['booking']);
  return(
    <>
      {t('date.header')}
    </>
  );
}

export default DatePickerHeader;
