import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

function TimePickerHeader(props){
  const { t } = useTranslation(['booking']);
  return(
    <>
      <span>{t('time.header')}</span>

      <Row style={{width:'130px'}} className="mx-auto">
        <Col className="text-end m-0 p-0">
          <span className="me-2">24h</span>
        </Col>
        <Col className="text-start m-0 p-0">
          <Form.Switch id="a" label="am/pm" checked={props.ampm} onClick={() => props.toggleAmpm()}/>
        </Col>
      </Row>
    </>
  );
}

export default TimePickerHeader;
