import OwnerInfo from './OwnerInfo/OwnerInfo';
import EventInfo from './EventInfo/EventInfo';
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';
import './details.css';

function Details({event,owner,payment,loading,setBookingStep}){
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const { t } = useTranslation(['booking']);
  
  return(
    <div className="details">
      <OwnerInfo owner={owner} loading={loading}/>
      <EventInfo event={event} payment={payment} loading={loading}/>
      {isMobile && <button className="schedule-btn" onClick={() => setBookingStep(2)}>{t(`details.schedule`)}</button>}
    </div >
  );
}

export default Details;
