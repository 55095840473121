import { useState } from 'react';
import Button from 'react-bootstrap/Button';

function CopyInviteLink({inviteLink}){
  const [copied,setCopied] = useState(false);

  const copyLink = () =>{
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => {setCopied(false)}, 3000);
  }

  return(
      <Button size="sm" variant="light" onClick={copyLink}>
        <b className="me-1"> copy invite link </b>
        {copied
          ?
            <i className="far fa-check-circle"></i>
          :
            <i className="fa-solid fa-copy"></i>
        }
      </Button>
  );
}

export default CopyInviteLink;
