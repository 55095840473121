import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import auth from '../../Account/Auth';

function Shortcuts(){

  const {t} = useTranslation(['navbar'])
  const location = useLocation();
  const borderStyle = {borderBottom : '2px solid'}
  const hasAgency = auth.getProperty({
    storageKey: 'userSession',
    path: ['profileObj','agencyId'],
    fallback: null
  });
  const agencyLink = (hasAgency ? '/agency' : '/upgrade#agency')


  return (
    <Nav.Item>
      <Link to="/calendarconnections">
        <div
          className="d-none d-md-inline-block text-white ms-lg-4 me-3 me-lg-5"
          style={location.pathname === '/calendarconnections' ? borderStyle : {}}
        >
          {t('calendarConnections')}
        </div>
      </Link>
      
      <Link to="/integrations">
        <div
          className="d-none d-md-inline-block text-white me-3 me-lg-5"
          style={location.pathname === '/integrations' ? borderStyle : {}}
        >
          {t('integrations')}
        </div>
      </Link>
      
      <Link to="/team">
        <div 
          className="d-none d-md-inline-block text-white me-3 me-lg-5"
          style={location.pathname === '/team' ? borderStyle : {}}
        >
          Team
        </div>
      </Link>
      
      <Link to="/embed">
        <div
          className="d-none d-md-inline-block text-white me-3 me-lg-5"
          style={location.pathname === '/embed' ? borderStyle : {}}
        >
          {t('embedPage')}
        </div>
      </Link>

      <Link to={agencyLink}>
        <div
          className="d-none d-md-inline-block text-white me-3 me-lg-5"
          style={location.pathname === '/agency' ? borderStyle : {}}
        >
          Agency
        </div>
      </Link>

      <Link to="/upgrade">
        <div
          className="d-none d-md-inline-block text-white"
          style={location.pathname === '/upgrade' ? borderStyle : {}}
        >
          <Button size="sm">Upgrade</Button>
        </div>
      </Link>
    </Nav.Item>
  );
}

export default Shortcuts;
