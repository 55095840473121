import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import './i18n';
import './style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import auth from './Account/Auth';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B6ZFCZBHLQ");

document.addEventListener('DOMContentLoaded', () => {
  console.log("DOM content loaded");
});

Sentry.init({
  dsn: "https://6374cff768cf406ab6d64d17f2ada9b1@o520706.ingest.sentry.io/5631429",
  release: process.env.REACT_APP_RELEASE_VERSION,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

Sentry.configureScope(function(scope) {
  if(auth.sessionExist()){
    const userInfo = {
      email: auth.getEmail(),
      username: auth.getUsername(),
      plan: auth.getRole(),
      timezone: auth.getTimezone()
    }

    scope.setUser(userInfo);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <GoogleOAuthProvider clientId="93252394910-584iq1mr08q684hurrfma0i9fct9cs82.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
