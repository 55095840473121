import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function OwnerName(props){
  return(
    <>
      {props.state}
    </>
  );
}

export default OwnerName;
