import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './calendbook-calendar.css';
import './timezone-picker.css';
import {Card,Col,Row} from 'react-bootstrap';
import Calendar from 'react-calendar';
import DatePickerHeader from './DatePickerHeader';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import spacetime from 'spacetime'
import i18n from '../../i18n';


function getUserLanguage(){
  return i18n.language.substring(0,2);
}

// INPUT: Object con valori booleani per ogni giorno della settimana
//        (cominciano da lunedì)
// OUTPUT: Array con valori booleani
//         (Cominciano da domenica - Array[0] si riferisce a domenica)
// INFO: serve per usare il DatePicker che usa giorni a partire da domenica.
//       Rende più robusta la gestione dei giorni, in qualsiasi ordine li ricevo
//       li riordino in base all'Array sorter
function parseAvailabilities(availabilityObj){
  var sorter = ['sun','mon','tue','wed','thu','fri','sat']
  let parsedDays = []
  sorter.map(day => {
    parsedDays.push(availabilityObj[day])
  })
  return parsedDays
}

// INPUT: Array con valori booleani
// OUTPUT: Array con valori numerici da 0 a 7
//         Esempio: [0,6] indica che domenica e sabato sono disabilitati
function getDisabledWeekDays(availabilityArray,ranges,ownerTimezone,guestTimezone){
  let timezoneArray = availabilityArray.slice();
  let wdays = ['sun','mon','tue','wed','thu','fri','sat']
  for (let i = 0; i < availabilityArray.length; i++){
    if(availabilityArray[i]){
      let day = wdays[i]
      let numRanges = ranges[day].length
      let owner = spacetime(new Date(),ownerTimezone)
      let firstTime = ranges[day][0].start
      let lastTime = ranges[day][numRanges-1].end

      owner = owner.time(firstTime)
      let guest = owner.clone()
      guest = guest.goto(guestTimezone)
      if(guest.day() < owner.day()){
        if(i >= 1){timezoneArray[i-1]=true}
        else{timezoneArray[6]=true}
      }

      owner = owner.time(lastTime)
      guest = owner.clone()
      guest = guest.goto(guestTimezone)
      if(guest.day() > owner.day()){
          if(i < 6){timezoneArray[i+1]=true}
        else{timezoneArray[0]=true}
      }
    }
  }
  let disabledDays = [];
  for (let i = 0; i < timezoneArray.length; i++) {
    if(!timezoneArray[i]){
      disabledDays.push(i);
    }
  }
  return disabledDays;
}

function _DatePicker(props){
  const componentStyle = {
    background:"var(--color-background-1)",
    height:500,
  }
  // Mantengo il giorno selezionato se c'è nello stato di _Booking, altrimenti
  // seleziono il giorno odierno
  var calendarDate = "";
  props.selectedDay ? calendarDate=new Date(props.selectedDay) : calendarDate=new Date();
  let ranges=props.ranges;
  let ownerTimezone=props.ownerTimezone;
  let guestTimezone=props.selectedTimezone;
  let availabilityArray=parseAvailabilities(props.availability);
  // carico i giorni della settimana da disabilitare
  const disabled = getDisabledWeekDays(availabilityArray,ranges,ownerTimezone,guestTimezone);

  return(
    <>
      <Card style={componentStyle}>
        <Card.Header className="text-center" ><DatePickerHeader/></Card.Header>
        <Card.Body>
          <Calendar
          className="mx-auto"
          minDate = {new Date()}
          value = {calendarDate}
          tileDisabled={({date, view}) => (view === 'month') && disabled.some(value => date.getDay() === value)}
          prev2Label = {null}
          next2Label = {null}
          onClickDay = {(date)=>(props.updateDay(date))}
          locale={getUserLanguage()}
          />
        </Card.Body>
        <Card.Footer >
          <Row>
          <Col xs={2}>
            <div className="text-center my-1"> <i className="fas fa-globe-americas mx-auto"></i> </div>
          </Col>
          <Col xs={10}>
            <TimezonePicker
              absolute={true}
              defaultValue={props.selectedTimezone}
              onChange={(timezone) => {props.updateTimezone(timezone)}}
            />
          </Col>
            </Row>
        </Card.Footer>
      </Card>
    </>
  );
}

export default _DatePicker;
