import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function EventName(props){
  return(
    <>
      <span className="h3">{props.state}</span>
    </>
  );
}

export default EventName;
