import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DOMPurify from 'dompurify';

function toHyperlink(text)
{
  //eslint-disable-next-line
  var pattern1 = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  var str1 = text.replace(pattern1, "<a href='$1'>$1</a>");
  //eslint-disable-next-line
  var pattern2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
  var str2 = str1.replace(pattern2, '$1<a target="_blank" href="http://$2">$2</a>');

  return str2;
}

function EventLocation(props){
  const location = props.state;
  var locationValue = ''
  if(typeof(location) === 'string'){
    console.info("[booking][details] location type is 'string'...")
    locationValue = DOMPurify.sanitize(location)
  }
  if(typeof(location) === 'object'){
    console.info("[booking][details] location type is 'object'...")
    locationValue = DOMPurify.sanitize(location.value)
  }
  return(
    <>
      <i class="fas fa-map-marker-alt me-1"></i>
      <span dangerouslySetInnerHTML={{__html: toHyperlink(locationValue)}} />
    </>
  );
}

export default EventLocation;
