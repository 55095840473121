import {useEffect, useState} from 'react';
import Heading from './Heading';
import ConnectZoom from './ConnectZoom';
import DisconnectZoom from './DisconnectZoom';
import UserInfo from './UserInfo/UserInfo';
import be from '../../BE';
import auth from '../../Account/Auth';

function Zoom(){
  const [zoomUser,setZoomUser] = useState({})
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    console.info("[zoom] loading user info")
    const checkConnectionStatus = async () => {
      setLoading(true)
        await auth.isAuthenticated()
        .then(async () => {
          await be.get('integrations','/zoom/userProfile',false,true)
          .then(response => {
            setZoomUser(response);
          })
          .catch((e) => {
            console.info("[integrations][zoom] Error fetching user profile:",e);
          });
        })
        .catch((e) => {
          console.info("[integrations][zoom] Error authenticating user:",e);
        });
      setLoading(false)
    }
    checkConnectionStatus()
  },[])

  const isConnected = (_obj) => {
    return Object.keys(_obj).length > 0
  }

  return(
    <>
      <Heading/>
      {loading
        ?
          <>Loading...</>
        :
          <>
            {isConnected(zoomUser) && <UserInfo zoomUser={zoomUser}/>}
            {isConnected(zoomUser) && <DisconnectZoom zoomUser={zoomUser} setZoomUser={setZoomUser}/>}
            {!isConnected(zoomUser) && <ConnectZoom/>}
          </>
      }
    </>
  );
}

export default Zoom;
