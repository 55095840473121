import React, { useState, useEffect } from 'react';
import MemberRow from './MemberRow';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from 'react-bootstrap/Spinner';
import FormControl from 'react-bootstrap/FormControl';
import CopyInviteLink from './CopyInviteLink';
import UpgradeMembers from './UpgradeMembers';
import DowngradeMembers from './DowngradeMembers';
import 'bootstrap/dist/css/bootstrap.min.css';
import auth from '../Account/Auth';
import be from '../BE';
import ReactGA from "react-ga4";

function Team(props) {
  ReactGA.send({ hitType: "pageview", page: "/team", title: "Team" });
  try{window.tidioChatApi.show()}catch{}
  const [teamStatus, setTeamStatus] = useState({
    viewerRole:'',
    seats:{
      occupied:0,
      total:0
    },
    members: []
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [invitingMember, setInvitingMember] = useState(false);
  const [validatedForm,setValidatedForm] = useState(false);
  const [newMember, setNewMember] = useState("");
  const [selectedMembers,setSelectedMembers] = useState([]);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getTeamStatus = async () => {
      setPageLoading(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get("team-manager","/team/members",{},true,true)
          .then((response) => {setTeamStatus(response);})
          .catch((response) => {console.log('error in /team page: ',response);})
        })
        .catch(()=>{})
      setPageLoading(false)
    }

  React.useEffect(() => {
    getTeamStatus();
  },[])

  const tableStyle={
    color: 'var(--color-text)',
    '--bs-table-color': 'var(--color-text)',
    '--bs-table-bg': 'var(--color-background-1)',
    '--bs-table-border-color': 'var(--color-border-light)'
  }

  const handleChange = (event) => {
    setNewMember(event.target.value);
  }

  const handleMemberSelection = (event) => {
    const isSelected = event.target.checked;
    const memberKey = event.target.getAttribute("data-key");
    const memberPlan = event.target.getAttribute("data-plan");
    const member = {
      key: memberKey,
      plan: memberPlan
    }
    setSelectedMembers(prevSelected => {
      if (isSelected) {
        // Add to selected state array without repetition
        return [...new Set([...prevSelected, member])];
      } else {
        // Remove from array
        return prevSelected.filter(item => item?.key !== member?.key);
      }
    });
  }

  async function inviteMember(e){
    const form = e.currentTarget;
    if(form.checkValidity() === false){
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setValidatedForm(true);
      setInvitingMember(true);

      /* be call */
      await auth.isAuthenticated()
        .then(async () => {
          await be.post("team-manager","/team/invitee",{email:newMember},true,true)
          .then((response) => {console.log("invited member: ",newMember);window.location.reload();})
          .catch((response) => {console.log('error in inviting member: ',response);})
        })
        .catch(()=>{})

      setInvitingMember(false);
    }
  }
  async function removeMember(_key){
    /* be call */
    await auth.isAuthenticated()
      .then(async () => {
        await be.post("team-manager","/team/remove",{member:_key},true,true)
        .then((response) => {console.log("removed member: ",_key);window.location.reload();})
        .catch((response) => {console.log('error in removing member: ',response);})
      })
      .catch(()=>{})
  }

  return(
    <Container className="my-5">
      {pageLoading
        ?
          <>
            Loading...
          </>
          :
          <>
            <Row>
              <h3>Team Management</h3>
            </Row>

              <Row className="mb-4">
                <Col xs={12} className="mb-2">
                  <b>Invite a member via mail</b>
                  {teamStatus?.invitationLink && 
                    <>
                      <b> or </b>
                      <CopyInviteLink inviteLink={teamStatus?.invitationLink}/>
                    </>
                  } 
                </Col>

                <Col xs={12} sm={8} md={6} lg={4}>
                  <Form noValidate validated={validatedForm} onSubmit={inviteMember}>
                    <InputGroup hasValidation>
                      <FormControl
                        required
                        type="email"
                        name="invite-member-email"
                        id="invited-member-email"
                        placeholder="team.member@gmail.com"
                        value={newMember}
                        onChange={handleChange}
                        style={{color:'var(--color-text)',backgroundColor:'var(--color-background-2)',borderColor:'var(--color-border-light)',}}
                      />
                      <Button disabled={invitingMember} type="submit">
                        {invitingMember 
                          ? 
                            <Spinner size="sm" animation="border" variant="light" /> 
                            : 
                            <i className="fas fa-long-arrow-alt-right"></i>
                        }
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>

            {teamStatus?.totalMembers && teamStatus?.members.length > 0 &&
            <Row className="mt-2">
              <Col>
                <b>Team Members: </b>
                {teamStatus?.totalMembers}
              </Col>
            </Row>
            }

            {selectedMembers && selectedMembers.length > 0
              ?
              <Row className="mt-2">
                <Col>
                  <b>Selected Members: </b>
                  <span className="me-2">{selectedMembers.length}</span>
                  {(teamStatus?.canUpgrade || teamStatus?.viewerRole === "owner") &&
                    <>
                      <UpgradeMembers getTeamStatus={getTeamStatus} canUpgrade={teamStatus?.canUpgrade} teamId={teamStatus?.teamId} viewerRole={teamStatus?.viewerRole} selectedMembers={selectedMembers}/>
                      <DowngradeMembers getTeamStatus={getTeamStatus} canUpgrade={teamStatus?.canUpgrade} teamId={teamStatus?.teamId} viewerRole={teamStatus?.viewerRole} selectedMembers={selectedMembers}/>
                      <div style={{fontSize:14}}>
                        <div><b>You can upgrade:</b> free members</div>
                        <div><b>You can downgrade:</b> paid plan members with same plan</div>
                      </div>
                    </>
                  }
                </Col>
              </Row>
              :
              <Row className="mt-2">
                <Col>
                  <span>Select members checkbox to upgrade or downgrade them</span>
                </Col>
              </Row>
            }

            <Row className="mt-2">
              {teamStatus?.members.length > 0 
                ?
                  <Table responsive style={tableStyle}>
                    <thead>
                      <tr align="center">
                        <th>Member</th>
                        <th>Plan</th>
                        <th>Role</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamStatus?.members.map(member => (
                        <MemberRow 
                          key={member?.key}
                          viewerRole={teamStatus?.viewerRole}
                          canPromoteList={teamStatus?.canPromoteList}
                          member={member}
                          removeMember={removeMember}
                          selected={selectedMembers.some(selectedMember => selectedMember?.key === member?.key)}
                          handleMemberSelection={handleMemberSelection}
                          canUpgrade={teamStatus?.canUpgrade}
                        />
                      ))}
                    </tbody>
                  </Table>
                :
                  <>No team members.</>
              }
            </Row>  
          </>
      }
    </Container>
  );
}

export default Team;
