import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EventName from './EventName';
import EventDescription from './EventDescription';
import EventLocation from './EventLocation';
import EventDuration from './EventDuration';
import Price from './Price';



function EventInfo(props){
  return(
    <>
      <div className="mb-3"> <EventName state={props.state.name}/> </div>
      <div className="mb-2"> <EventDuration state={props.state.duration}/> </div>
      <div className="mb-2"> <Price isPaidEvent={props.isPaidEvent} paidEvent={props.paidEvent}/> </div>
      <div className="mb-2"> <EventLocation state={props.state.location}/> </div>
      <div className="mb-2"> <EventDescription state={props.state.description}/> </div>
    </>
  );
}

export default EventInfo;
