import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Zapier from './Zapier/Zapier';
import Webhooks from  './Webhooks/Webhooks';
import UpgradePath from '../Utilities/UpgradePath/UpgradePath';
import Zoom from './Zoom/Zoom';
import Stripe from './Stripe/Stripe';
import SalesForza from './SalesForza/SalesForza';
import Telegram from './Telegram/Telegram';
import Whatsapp from './Whatsapp/Whatsapp';
import ReactGA from "react-ga4";

function Integrations () {
  let activeKey = ""
  switch(window.location.href.split('#')[1]){
    case "stripe" : activeKey = "stripe"; break;
    case "zoom" : activeKey = "zoom"; break;
    case "zapier" : activeKey = "zapier"; break;
    case "webhooks" : activeKey = "webhooks"; break;
    case "salesforza" : activeKey = "salesforza"; break;
    case "telegram" : activeKey = "telegram"; break;
    case "whatsapp" : activeKey = "whatsapp"; break;
    default: activeKey = "stripe";
  }
  ReactGA.send({ hitType: "pageview", page: `/integrations#${activeKey}`, title: `Integrations - ${activeKey}` });
  try{window.tidioChatApi.show()}catch{}

  return(
    <Container className="mt-5">
      <Tabs defaultActiveKey={activeKey} id="integrations-tab">

        <Tab eventKey="stripe" title="Stripe">
          <Stripe/>
        </Tab>

        <Tab eventKey="zoom" title="Zoom">
          <UpgradePath
            featureName="zoom"
            position="right"
          >
            <Zoom/>
          </UpgradePath>
        </Tab>

        <Tab eventKey="zapier" title="Zapier">
          <UpgradePath
            featureName="zapier-integration"
            position="right"
          >
            <Zapier/>
          </UpgradePath>
        </Tab>

        <Tab eventKey="webhooks" title="Webhooks">
          <UpgradePath
            featureName="webhooks"
            position="right"
          >
            <Webhooks/>
          </UpgradePath>
        </Tab>

        <Tab eventKey="salesforza" title="Salesforza">
          <UpgradePath
            featureName="salesforza"
            position="right"
          >
            <SalesForza/>
          </UpgradePath>
        </Tab>

        <Tab eventKey="telegram" title="Telegram">
            <Telegram/>
        </Tab>

        <Tab eventKey="whatsapp" title="Whatsapp">
            <Whatsapp/>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Integrations;
