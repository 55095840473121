import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function OwnerPic(props){
  return(
    <>
      <img src={props.state} width="60px" className="img-fluid rounded-circle" alt="owner-pic" />
    </>
  );
}

export default OwnerPic;
