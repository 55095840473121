import React, {useState, useEffect} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import {Tab, Row, Col, Button, Card, Accordion} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import spacetime from 'spacetime'
import RedeemCode from './RedeemCode/RedeemCode';
import CurrentFeatures from './CurrentFeatures/CurrentFeatures';
import be from '../../BE';
import auth from '../../Account/Auth';

const rulerStyle = {
  borderColor:'var(--color-ruler)',
  marginTop:10,
  marginBottom:0
}

async function cancelSubscription() {

  await auth.isAuthenticated()
    .then(async () => {
      await be.get("payments","/cancelsub",false,true)
        .then(() => auth.refresh())
        .then(() => window.location.href = '/')
        .catch(error => console.log(error))
    })
    .catch(() => this.props.history.push('/login'))
}

async function getCustomerPortal() {
  let customerPortalLink = ""

  await auth.isAuthenticated()
    .then(async () => {
      await be.get("payments","/customer",false,true)
        .then((r) => {customerPortalLink =  r.state })
    })
    .catch(error => console.log(error))

  return customerPortalLink;
}



function SubscriptionPanel(){
  const { t } = useTranslation(['settings','common']);
  const [unsubscribeAlert,setUnsubscribeAlert] = useState(false)
  const [portal,setPortal] = useState("")

  useEffect(() => {
    const fetchPortal = async () => {
      let result = "" 
      await auth.isAuthenticated()
      .then(async () => {result = await getCustomerPortal()})
      .catch((e) => {console.log(e)})
      setPortal(result);
    }
    // fetching customer portal only for premium users
    if(auth.isPremium()){fetchPortal()}
  },[]);

  function handleUnsubscribe(){
    setUnsubscribeAlert(true)
  }

  // returns a string with human readable remaining time
  // e.g. 1 year, 11 months, 28 days (2021/08/31)
  function getRemainingSubTime(){
    let today = spacetime(new Date())
    let expiration = spacetime(auth.getExpiration())
    let remainingSubTime = today.since(expiration)
    let remainingYears = Math.abs(remainingSubTime.diff.years)
    let remainingMonths = Math.abs(remainingSubTime.diff.months)
    let remainingDays = Math.abs(remainingSubTime.diff.days)

    let formattedTime = ""

    if(remainingYears > 0){
      let yearUnit = t('common:years')
      if(remainingYears === 1){yearUnit = t('common:year')}
      formattedTime += remainingYears + " " + yearUnit + ", "
    }
    if(remainingMonths > 0){
      let monthUnit = t('common:months')
      if(remainingMonths === 1){monthUnit = t('common:month')}
      formattedTime += remainingMonths + " " + monthUnit + ", "
    }
    if(remainingDays > 0){
      let dayUnit = t('common:days')
      if(remainingDays === 1){dayUnit = t('common:day')}
      formattedTime += remainingDays + " " + dayUnit + " "
    }
    
    formattedTime += " (" + auth.getExpiration() +")"
    
    return formattedTime;
  }

  return(
    <Tab.Pane eventKey="subscription">
      <Row className="mt-3 mb-4" >
        <Col>
          <h4>{t('subscription.currentSubscription')}</h4>
          {/* Show current account status/role 
              Showing for: [*] users
          */}
          {t('subscription.roleMessage',{role : auth.getRole()})}

          {/* Show number of stacked lifetime codes
              Showing for: [lifetime] users
          */}
          {/*
            auth.isLifetime() && 
            <div>
              {t('subscription.stackedCodes',{stacked : "00000000000000"})}
            </div>
          */
          }

          {/* DEPRECATED: Show remaining subscription time before next billing/expiration
              Showing for: [premium] users
          */}
          {/*auth.isPremium() && 
            <div>
              {t('subscription.expiresIn')}{getRemainingSubTime()}
            </div>
          */}

          {/* Show upgrade page link
              Showing for: [trial, free] users
          */}
          {/*( auth.isTrial() || auth.isFree() ) &&
            <>
              <Link to={{pathname: '/upgrade'}} target="_blank" rel="noreferrer">
                <b className="mx-1">{t('common:upgradeMessage')}</b>
              </Link>
            </>
          */}

          {/* Show current features unlocked
              Showing for: [*] users
          */}
          <div className="mt-3">
            <h6>{t('subscription.yourCurrentFeatures')}</h6>
            {auth.isFree()
              ?
                <CurrentFeatures
                  features={{
                    0:true,
                    1:false,
                    2:false,
                    3:false,
                    4:false
                  }}
                />
              :
                <CurrentFeatures
                  features={{
                    0:true,
                    1:true,
                    2:true,
                    3:true,
                    4:true
                  }}
                />
            }
          </div>
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      {/* DEPRECATED: Show stripe customer portal
          Showing for: [premium] users
      */}
      {/*auth.isPremium() &&
        <Row className="my-4">
          <Col xs={12} md={8} className="my-auto">
              <h4>{t('subscription.stripeBilling')}</h4>
              {t('subscription.customerPortalLabel')}
              <div className="d-inline-block ms-2">
                <OverlayTrigger 
                  trigger={['hover','click']}
                  overlay={<Tooltip id={`tooltip-settings-customer-portal`}>{t('subscription.customerPortalInfo')}</Tooltip>}
                >
                  <i className="far fa-question-circle"></i>
                </OverlayTrigger>
              </div>
          </Col>
          <Col xs="auto" md={4} className="my-2 my-md-auto float-start float-md-end">
            <a href={portal} target="_blank" rel="noopener noreferrer">
              <Button block>
                {t('subscription.customerPortalLink')}
              </Button>
            </a>
          </Col>
        </Row>
      */}
      {/* Show redeem code input
          Showing for: [trial, free, lifetime] users
      */}
      {( auth.isTrial() || auth.isFree() || auth.isLifetime() ) &&
        <Row className="my-4">
          <Col xs={12} md={8}>
            <RedeemCode/>
          </Col>
        </Row>

      }
      {/* Danger Zone, deprecated but keeping it just in case */}
      {/* keeping libs: SweetAlert,Card,Accordion,cancelSubscription,handleUnsubscribe */}
      {/* WARN: Using a deprecated version of Accordion, read new implementation in AccountPanel */}
      {/*
      {auth.isPremium() &&
      <>
        <Accordion>
          <Card className="my-3" style={{background:"var(--color-background-1)"}}>
            <Card.Header>
              <Accordion.Button as={Button} variant="link" className="text-danger" eventKey="0">
                Danger Zone
              </Accordion.Button>
            </Card.Header>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col xs={12} className="my-3">
                    <Row>
                      <Col xs={12} md={8} className="my-auto">
                        {t('subscription.dangerous.cancelSubscriptionDescription')}
                      </Col>
                      <Col xs="auto" md={4} className="my-2 my-md-auto float-start float-md-end">
                        <Button block variant='outline-danger' onClick={() => (handleUnsubscribe())}>{t('subscription.dangerous.cancelSubscriptionButton')}</Button>
                        {unsubscribeAlert &&
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes, unsubscribe me"
                          confirmBtnStyle={{border:'none',boxShadow:'none'}}
                          confirmBtnBsStyle="link"
                          cancelBtnBsStyle="primary"
                          title="Are you sure?"
                          onConfirm={async () => cancelSubscription()}
                          onCancel={() => setUnsubscribeAlert(false)}
                          reverseButtons={true}
                          focusCancelBtn={true}
                          customClass='dark-bg'
                        />
                        }
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
      }
      */}
    </Tab.Pane>
  );
}

export default SubscriptionPanel;
