import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from 'react-bootstrap';
import _OwnerInfo from './OwnerInfo/_OwnerInfo';
import _EventInfo from './EventInfo/_EventInfo';
import { useTranslation } from 'react-i18next';


function _Details(props){
  const { t } = useTranslation(['booking']);
  const componentStyle = {
    background:"var(--color-background-1)",
    height:400,
  }
  return(
    <>
      <Card style={componentStyle}>
        <Card.Header className="text-center">{t('details.header')}</Card.Header>
        <Card.Body>
          <_OwnerInfo state={props.state.ownerInfo}/>
          <_EventInfo state={props.state.eventInfo} isPaidEvent={props.isPaidEvent} paidEvent={props.paidEvent}/>
        </Card.Body>
      </Card>
    </>
  );
}

export default _Details;
